import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Container} from './mentions-legales.styles';

const Text = () => (
    <div>
    <p>1. Pr&eacute;sentation du site.<br />
En vertu de l'article 6 de la loi n&deg; 2004-575 du 21 juin 2004 pour la confiance dans l'&eacute;conomie num&eacute;rique, il est pr&eacute;cis&eacute; aux utilisateurs du site brian-charles-dev.com l'identit&eacute; des diff&eacute;rents intervenants dans le cadre de sa r&eacute;alisation et de son suivi :</p>

<p>Propri&eacute;taire : Brian Charles &ndash; Impasse du Malhivert, Les Itardes, Le Castellet</p>

<p>Cr&eacute;ateur : Brian CHARLES - brian-charles-dev.com</p>

<p>Num&eacute;ro SIRET : 821328721</p>

<p>Responsable publication : Brian CHARLES</p>

<p>Webmaster : Brian CHARLES &ndash; briancharlesdev@gmail.com</p>

<p>H&eacute;bergeur : Firebase, 188 King ST, San Francisco, CA 94107, United States, https://firebase.google.com/</p>

<p>2. Conditions g&eacute;n&eacute;rales d'utilisation du site et des services propos&eacute;s.<br />
L'utilisation du site brian-charles-dev.com implique l'acceptation pleine et enti&egrave;re des conditions g&eacute;n&eacute;rales d'utilisation ci-apr&egrave;s d&eacute;crites. Ces conditions d'utilisation sont susceptibles d'&ecirc;tre modifi&eacute;es ou compl&eacute;t&eacute;es &agrave; tout moment, les utilisateurs du site brian-charles-dev.com sont donc invit&eacute;s &agrave; les consulter de mani&egrave;re r&eacute;guli&egrave;re.</p>

<p>Ce site est normalement accessible &agrave; tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut &ecirc;tre toutefois d&eacute;cid&eacute;e par brian-charles-dev.com, qui s'efforcera alors de communiquer pr&eacute;alablement aux utilisateurs les dates et heures de l'intervention.</p>

<p>Le site brian-charles-dev.com est mis &agrave; jour r&eacute;guli&egrave;rement par Brian CHARLES. De la m&ecirc;me fa&ccedil;on, les mentions l&eacute;gales peuvent &ecirc;tre modifi&eacute;es &agrave; tout moment : elles s'imposent n&eacute;anmoins &agrave; l'utilisateur qui est invit&eacute; &agrave; s'y r&eacute;f&eacute;rer le plus souvent possible afin d'en prendre connaissance.</p>

<p>3. Description des services fournis.<br />
Le site brian-charles-dev.com a pour objet de fournir une information concernant l'ensemble des activit&eacute;s de la soci&eacute;t&eacute;.</p>

<p>Brian CHARLES s'efforce de fournir sur le site brian-charles-dev.com des informations aussi pr&eacute;cises que possible. Toutefois, il ne pourra &ecirc;tre tenue responsable des omissions, des inexactitudes et des carences dans la mise &agrave; jour, qu'elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.</p>

<p>Tous les informations indiqu&eacute;es sur le site brian-charles-dev.com sont donn&eacute;es &agrave; titre indicatif, et sont susceptibles d'&eacute;voluer. Par ailleurs, les renseignements figurant sur le site brian-charles-dev.com ne sont pas exhaustifs. Ils sont donn&eacute;s sous r&eacute;serve de modifications ayant &eacute;t&eacute; apport&eacute;es depuis leur mise en ligne.</p>

<p>4. Limitations contractuelles sur les donn&eacute;es techniques.<br />
Le site utilise la technologie JavaScript.</p>

<p>Le site Internet ne pourra &ecirc;tre tenu responsable de dommages mat&eacute;riels li&eacute;s &agrave; l'utilisation du site. De plus, l'utilisateur du site s'engage &agrave; acc&eacute;der au site en utilisant un mat&eacute;riel r&eacute;cent, ne contenant pas de virus et avec un navigateur de derni&egrave;re g&eacute;n&eacute;ration mis-&agrave;-jour.</p>

<p>5. Propri&eacute;t&eacute; intellectuelle et contrefa&ccedil;ons.<br />
Brian CHARLES est propri&eacute;taire des droits de propri&eacute;t&eacute; intellectuelle ou d&eacute;tient les droits d'usage sur tous les &eacute;l&eacute;ments accessibles sur le site, notamment les textes, images, graphismes, logo, ic&ocirc;nes, sons, logiciels.</p>

<p>Toute reproduction, repr&eacute;sentation, modification, publication, adaptation de tout ou partie des &eacute;l&eacute;ments du site, quel que soit le moyen ou le proc&eacute;d&eacute; utilis&eacute;, est interdite, sauf autorisation &eacute;crite pr&eacute;alable de : Brian CHARLES.</p>

<p>Toute exploitation non autoris&eacute;e du site ou de l'un quelconque des &eacute;l&eacute;ments qu'il contient sera consid&eacute;r&eacute;e comme constitutive d'une contrefa&ccedil;on et poursuivie conform&eacute;ment aux dispositions des articles L.335-2 et suivants du Code de Propri&eacute;t&eacute; Intellectuelle.</p>

<p>6. Limitations de responsabilit&eacute;.<br />
Brian CHARLES ne pourra &ecirc;tre tenue responsable des dommages directs et indirects caus&eacute;s au mat&eacute;riel de l'utilisateur, lors de l'acc&egrave;s au site brian-charles-dev.com, et r&eacute;sultant soit de l'utilisation d'un mat&eacute;riel ne r&eacute;pondant pas aux sp&eacute;cifications indiqu&eacute;es au point 4, soit de l'apparition d'un bug ou d'une incompatibilit&eacute;</p>

<p>Brian CHARLES ne pourra &eacute;galement &ecirc;tre tenue responsable des dommages indirects (tels par exemple qu'une perte de march&eacute; ou perte d'une chance) cons&eacute;cutifs &agrave; l'utilisation du site brian-charles-dev.com.</p>

<p>Des espaces interactifs (possibilit&eacute; de poser des questions dans l'espace contact) sont &agrave; la disposition des utilisateurs. Brian CHARLES se r&eacute;serve le droit de supprimer, sans mise en demeure pr&eacute;alable, tout contenu d&eacute;pos&eacute; dans cet espace qui contreviendrait &agrave; la l&eacute;gislation applicable en France, en particulier aux dispositions relatives &agrave; la protection des donn&eacute;es. Le cas &eacute;ch&eacute;ant, Brian CHARLES se r&eacute;serve &eacute;galement la possibilit&eacute; de mettre en cause la responsabilit&eacute; civile et/ou p&eacute;nale de l'utilisateur, notamment en cas de message &agrave; caract&egrave;re raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilis&eacute; (texte, photographie&#8230;).</p>

<p>7. Gestion des donn&eacute;es personnelles.<br />
En France, les donn&eacute;es personnelles sont notamment prot&eacute;g&eacute;es par la loi n&deg; 78-87 du 6 janvier 1978, la loi n&deg; 2004-801 du 6 ao&ucirc;t 2004, l'article L. 226-13 du Code p&eacute;nal et la Directive Europ&eacute;enne du 24 octobre 1995.</p>

<p>&Agrave; l'occasion de l'utilisation du site brian-charles-dev.com, peuvent &ecirc;tres recueillies : l'URL des liens par l'interm&eacute;diaire desquels l'utilisateur a acc&eacute;d&eacute; au site brian-charles-dev.com, le fournisseur d'acc&egrave;s de l'utilisateur, l'adresse de protocole Internet (IP) de l'utilisateur.</p>

<p>En tout &eacute;tat de cause Brian CHARLES ne collecte des informations personnelles relatives &agrave; l'utilisateur que pour le besoin de certains services propos&eacute;s par le site brian-charles-dev.com. L'utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu'il proc&egrave;de par lui-m&ecirc;me &agrave; leur saisie. Il est alors pr&eacute;cis&eacute; &agrave; l'utilisateur du site brian-charles-dev.com l'obligation ou non de fournir ces informations.</p>

<p>Conform&eacute;ment aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative &agrave; l'informatique, aux fichiers et aux libert&eacute;s, tout utilisateur dispose d'un droit d'acc&egrave;s, de rectification et d'opposition aux donn&eacute;es personnelles le concernant, en effectuant sa demande &eacute;crite et sign&eacute;e, accompagn&eacute;e d'une copie du titre d'identit&eacute; avec signature du titulaire de la pi&egrave;ce, en pr&eacute;cisant l'adresse &agrave; laquelle la r&eacute;ponse doit &ecirc;tre envoy&eacute;e.</p>

<p>Aucune information personnelle de l'utilisateur du site brian-charles-dev.com n'est publi&eacute;e &agrave; l'insu de l'utilisateur, &eacute;chang&eacute;e, transf&eacute;r&eacute;e, c&eacute;d&eacute;e ou vendue sur un support quelconque &agrave; des tiers. Seule l'hypoth&egrave;se du rachat de Brian CHARLES et de ses droits permettrait la transmission des dites informations &agrave; l'&eacute;ventuel acqu&eacute;reur qui serait &agrave; son tour tenu de la m&ecirc;me obligation de conservation . et de modification des donn&eacute;es vis &agrave; vis de l'utilisateur du site brian-charles-dev.com.</p>

<p>Le site n'est pas d&eacute;clar&eacute; &agrave; la CNIL car il ne recueille pas d'informations personnelles.</p>

<p>Les bases de donn&eacute;es sont prot&eacute;g&eacute;es par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative &agrave; la protection juridique des bases de donn&eacute;es.</p>

<p>8. Liens hypertextes et cookies.<br />
Le site brian-charles-dev.com contient un certain nombre de liens hypertextes vers d'autres sites, mis en place avec l'autorisation de Brian CHARLES. Cependant, Brian CHARLES n'a pas la possibilit&eacute; de v&eacute;rifier le contenu des sites ainsi visit&eacute;s, et n'assumera en cons&eacute;quence aucune responsabilit&eacute; de ce fait.</p>

<p>La navigation sur le site brian-charles-dev.com est susceptible de provoquer l'installation de cookie(s) sur l'ordinateur de l'utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l'identification de l'utilisateur, mais qui enregistre des informations relatives &agrave; la navigation d'un ordinateur sur un site. Les donn&eacute;es ainsi obtenues visent &agrave; faciliter la navigation ult&eacute;rieure sur le site, et ont &eacute;galement vocation &agrave; permettre diverses mesures de fr&eacute;quentation.</p>

<p>Le refus d'installation d'un cookie peut entra&icirc;ner l'impossibilit&eacute; d'acc&eacute;der &agrave; certains services. L'utilisateur peut toutefois configurer son ordinateur de la mani&egrave;re suivante, pour refuser l'installation des cookies :<br />
Sous Internet Explorer : onglet outil / options internet. Cliquez sur Confidentialit&eacute; et choisissez Bloquer tous les cookies. Validez sur Ok.</p>

<p>Cookies de sessions pr&eacute;sents sur ce site :</p>

<p>_ga, _gat, _gtag : Cookies propres &agrave; Google Analytics (statistiques)<br />
9. Droit applicable et attribution de juridiction.<br />
Tout litige en relation avec l'utilisation du site brian-charles-dev.com est soumis au droit fran&ccedil;ais. Il est fait attribution exclusive de juridiction aux tribunaux comp&eacute;tents de Toulon.</p>

<p>10. Les principales lois concern&eacute;es.<br />
Loi n&deg; 78-87 du 6 janvier 1978, notamment modifi&eacute;e par la loi n&deg; 2004-801 du 6 ao&ucirc;t 2004 relative &agrave; l'informatique, aux fichiers et aux libert&eacute;s.</p>

<p>Loi n&deg; 2004-575 du 21 juin 2004 pour la confiance dans l'&eacute;conomie num&eacute;rique.</p>

<p>11. Lexique.<br />
Utilisateur : Internaute se connectant, utilisant le site susnomm&eacute;.</p>

<p>Informations personnelles : &laquo; les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent &raquo; (article 4 de la loi n&deg; 78-17 du 6 janvier 1978).</p>
    </div>
)

const useStyles = makeStyles({
    button: {
      color: 'white',
    },
  });

const MentionsLegales = () => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const classes = useStyles();

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  return (
    <Container>
      <Button onClick={handleClickOpen('paper')}  className={classes.button} >Mentions légales</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Mentions légales</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {Text()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default MentionsLegales;