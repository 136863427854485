import React from "react";
import { ThemeProvider } from "styled-components";
import media from './media.js'

import { createMuiTheme } from '@material-ui/core/styles';

const first = "#74c5e0"
const second = "#005B9B"
const third = "#e5f8ff"
const fourth = "#005B9B"
const fifth ="#FCFCD4"

const theme = {
  colors: {
    main: first,
    second : second,
    third: third,
    fourth: fourth,
    fifth: fifth
  },
  filters: {
    main: "invert(38%) sepia(72%) saturate(473%) hue-rotate(148deg) brightness(88%) contrast(83%)",
    second : "invert(16%) sepia(60%) saturate(5299%) hue-rotate(192deg) brightness(95%) contrast(101%)",
    third: "invert(69%) sepia(43%) saturate(382%) hue-rotate(149deg) brightness(99%) contrast(87%)",
  },
  //fonts: ["sans-serif", "Roboto"],
  fontSizes: {
    small: "1rem",
    medium: "2rem",
    large: "3rem"
  },
  ...media
};

export const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export const MUItheme = createMuiTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: first,
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: second,
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffcc00',
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
  });
