import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
//import { Link } from 'react-router-dom';
import { Link} from "react-scroll";

import { OptionsContainer} from './navigation-links.styles';

const items = [
    { 
        componentName: "sectionServices",
        textName: "services"
    },
    {
        componentName: "sectionCompetences",
        textName: "competences"
    },
    {
        componentName: "sectionTarifs",
        textName: "tarifs"
    },
    {
        componentName: "sectionAPropos",
        textName: "a_propos"
    },
    {
        componentName: "sectionContact",
        textName: "contact"
    }
]

const NavigationLinks = ({orientation = "horizontal", color = "white", handleNavigationClick = () => null }) => {
    const { t} = useTranslation();

    const isMobile = useMediaQuery({ maxWidth: 767 })
    
    const handleClick = () => {
        if(isMobile) {
            handleNavigationClick(false);
        }
    }

    return (
        <OptionsContainer orientation={orientation} color={color}>
            {items.map(item => (
                <Link key={item.componentName} onClick={handleClick} activeClass="active" to={item.componentName} spy={true} smooth={true} offset={-60} duration={500} className="optionLink">
                    {t("header."+item.textName).toUpperCase()}
                </Link>
            ))}
        </OptionsContainer>
    )
};

export default NavigationLinks;