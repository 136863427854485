import styled, { css } from "styled-components";

export const IconContainer = styled.div`
    height: ${props => props.iconContainerSize};
    width: ${props => props.iconContainerSize};
    ${props => props.circled && css`
        border: solid 2px;
        border-radius: 200px;
    `}
    transition: 0.3s ease-in-out;
    filter: ${props => props.theme.filters.main};
`;

export const Container = styled.div`
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex: 1;
    align-items: center;
    background-color: white;
    ${props => props.perspective && css`
        box-shadow: 6px 4px 20px grey;
        transform: rotateY(0deg);
        transition: transform .3s;
    `}
    ${props => props.position === 'left' && css`
        flex-direction: row;
        ${props => props.perspective && css`
            :hover{
                transform: perspective(1000px) rotateY(5deg);
            }
        `}
        ${IconContainer} {
            margin-left: ${props => props.distanceIconInfos};
        }
        ${Title}, ${Description} {
            text-align: right;
        }
    `}
    ${props => props.position === 'right' && css`
        flex-direction: row-reverse;
        ${props => props.perspective && css`
            :hover{
                transform: perspective(1000px) rotateY(-5deg);
            }
        `}
        ${IconContainer} {
            margin-right: ${props => props.distanceIconInfos};
        }
        ${Title}, ${Description} {
            text-align: left;
        }
    `}
    ${(props) => props.theme.mobile`
        background-color: rgba(0,0,0,0);
   `}
`;

export const Title = styled.h3`
    font-size: 1.2rem;
    margin: 0;
    color: ${props => props.theme.colors.second};
    ${(props) => props.theme.mobile`
        font-size: 1rem;
   `}
`;

export const Description = styled.h4`
    font-size: 0.9rem;
    margin : 0;
    width: 100%;
    ${(props) => props.theme.mobile`
        font-size: 0.8rem;
   `}
`;

export const Infos = styled.div`
    position: relative;
    display: flex;
    width: 80%;
    flex-direction: column;
`;

export const Icon = styled.img`
    margin-top: ${props => (props.iconSize.split('p')[0]*0.4).toString()+"px"};
    height: ${props => props.iconSize};
    width: ${props => props.iconSize};
    /* ${props => props.iconColored && css`
        filter: invert(100%) sepia(4%) saturate(958%) hue-rotate(183deg) brightness(121%) contrast(100%);
    `} */
`;