import React, {forwardRef} from 'react';

import { Container, Infos, Title, Description, Icon, IconContainer } from './infos-with-icon.styles';

const InfosWithIcon = forwardRef(({title, description, icon, position, iconSize = 40, circled = true, perspective = true, iconColored= true, marginTop="1rem", mobile="false", titleColor="black", descriptionColor="white", distanceIconInfos="0.5rem", altIcon, type, prop}, ref) => {
    return (
        <Container ref={ref} position={position} perspective={perspective} marginTop={marginTop} distanceIconInfos={distanceIconInfos}>
            <Infos mobile={mobile} itemScope itemType={type === "service" ? "https://schema.org/Service" : "https://schema.org/Person"}>
                <Title titleColor={titleColor} itemProp={type === "service" ? "serviceType" : undefined}>
                    {title}
                </Title>
                <Description itemProp={type === "service" ? "description" : prop} descriptionColor={descriptionColor}>
                    {description}
                </Description>
            </Infos>
            <IconContainer circled={circled} iconContainerSize={(iconSize*1.7).toString()+"px"}>
                <Icon src={icon} alt={altIcon} iconSize={iconSize.toString()+"px"} iconColored={iconColored}/>
            </IconContainer>
        </Container>
    )
});

export default InfosWithIcon;