import React from 'react';
import { useTranslation } from 'react-i18next';

import Linkedin from '../../assets/linkedin.svg';
import Skype from '../../assets/skype.svg';
import Facebook from '../../assets/facebook.svg';

import { Container, Text, Icons, Icon} from "./social-networks.styles";

import { useMediaQuery } from 'react-responsive';

const Footer = () => {
    const { t} = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 })

    const icons = [
        {
            src: Linkedin,
            link: "https://www.linkedin.com/in/brian-charles-382b671b5/",
            alt: "logo Linkedin"
        },
        {
            src: Skype,
            link: "skype:live:.cid.4eb377fff60f49e6?chat",
            alt: "logo Skype"
        },
        {
            src: Facebook,
            link: "https://www.facebook.com/brian.charles.3323457?fref=search&__tn__=%2Cd%2CP-R&eid=ARBi__yy-mJF1oTS-lbgMwejB3VE9z-Y-KiWVmiT9jnLtGrcZ7tWBwML1Xc64yPZiUwyoL30xqofbRYL",
            alt: "logo Facebook"
        }
    ]

    return (
        <Container isMobile={isMobile}>
            <Text>
                {t('sections.footer.social')}
            </Text>
            <Icons>
            {icons.map((icon, index) =>
                (
                <a key={icon.alt} href={icon.link} title={icon.alt}>
                    <Icon src={icon.src} alt={icon.alt}/>
                </a>
            ))}        
            </Icons>
        </Container>
    )
};

export default Footer;