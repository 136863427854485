import styled from "styled-components";

export const Container = styled.div`
    width: 80%;
    margin-left: 10%;
    height: 10%;
    margin-top: 0.3rem;
    background-color: #EEEBEB;
`;

export const ColoredPart = styled.div`
    width: ${props => props.percentage};
    height: 100%;
    background-color: ${props => props.theme.colors.main};
    transition: width 3s ease-in-out;
    transition-delay: ${props => (props.index*200).toString()+"ms"};
`;

export const TextPart = styled.div`
    width: max-content;
    padding-left:10px;
    padding-right:10px;
    height: 100%;
    background-color: #8bcbe0;
    font-size: 1rem;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
`;