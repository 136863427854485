import React, {  useEffect, useRef } from 'react';
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";

import { Container} from './map.styles';

const position = [43.934, 5.966];

const icon = new Icon({
  iconUrl: require("../../assets/codingIcon.svg"),
  iconSize: [45, 45]
});

const SimpleMap = () => {
    const mapRef = useRef(null);

    useEffect(() => {
            const map = mapRef.current.leafletElement;
            setTimeout(() => { map.invalidateSize(); }, 10000); 
      }, []);


    return (
        <Container itemScope itemType="https://schema.org/Map">
                <Map center={position} zoom={6} ref={mapRef} itemProp="mapType" href="https://schema.org/SeatingMap">
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                />
                <Marker position={position} icon={icon}>
                    <Popup>Some programming nerd</Popup>
                </Marker>
            </Map>
        </Container>
      );
};

export default SimpleMap;