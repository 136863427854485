import React from 'react';

import SectionMesServices from '../../sections/section-mes-services/section-mes-services.component';
import SectionCompetences from '../../sections/section-competences/section-competences.component';
import SectionLandingAnimation from '../../sections/section-landing-animation/section-landing-animation.component';
import SectionPourquoiMoi from '../../sections/section-pourquoi-moi/section-pourquoi-moi.component';
import SectionImagination from '../../sections/section-imagination/section-imagination.component';
import SectionPricing from '../../sections/section-pricing/section-pricing.component';
import SectionContact from '../../sections/section-contact/section-contact.component';
import SectionDeroulement from '../../sections/section-deroulement/section-deroulement.component';
import SectionAPropos from '../../sections/section-a-propos/section-a-propos.component';
import Footer from '../../sections/footer/footer.component';

import { BigContainer} from './homepage.styles';

const HomePage = ({loaded}) => {
    return (
        <BigContainer>
            <SectionLandingAnimation loaded={loaded}/>
            <SectionMesServices />
            <SectionPourquoiMoi />
            <SectionImagination />
            <SectionCompetences/>
            <SectionPricing />
            <SectionDeroulement />
            <SectionAPropos />
            <SectionContact />
            <Footer />
        </BigContainer>
        
    )
};

export default HomePage;
