import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 0.8rem;
`;

export const ColumnContainer = styled.div`
    position: relative;
    width: 100%;
    height: 50%;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    ${(props) => props.theme.mobile`
        flex-direction: column;
    `}
`;