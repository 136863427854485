import React from 'react'
import { Link } from 'react-router-dom';
import { Container, NotFound } from './not-found-page.styles.jsx';
 
const NotFoundPage = props => {
    return (
        <Container>
            <NotFound/>
            <p style={{textAlign:"center"}}>
                <Link to="/">Go to Home </Link>
            </p>
        </Container>
    )
}
 
export default NotFoundPage;