import styled, {css} from 'styled-components';

import { ReactComponent as EntrepreneurSVG} from '../../assets/entrepreneur.svg';

export const Container= styled.div`
    position: relative;
    width: 100vw;
    height: 80vh;
    ${props => (props.isMobile) && css`
            height: 120vh;
        `}
    display: flex;
    flex-direction: column;
`;

export const TwoColumnContainer = styled.div`
    position: relative;
    width: 90%;
    height: 70%;
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    justify-content: space-evenly;
`;

export const EntrepreneurSvg = styled(EntrepreneurSVG)`
  flex: 0.4;
  margin-right: 5%;
  margin-left: 5%;
`;