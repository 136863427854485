import styled, { css } from 'styled-components';

export const Container= styled.div`
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 1.5;

    .big-circle{
        visibility: hidden;
    }

    svg {
        ${props => (props.isMobile) && css`
            width: 100%;
        `}
        ${props => (!props.isMobile) && css`
            
            margin-top: auto;
            margin-bottom: auto;
        `}
    }

    .text {
        opacity: 1;
    }
`;

export const Navigation = styled.div`
    position: relative;
    height: 10%;
    margin-left: 10%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1rem;
`;

export const SlideButtonLeft = styled.div`
    position: relative;
    width: 30%;
    font-size: 1rem;
    height: 1.5rem;
    border: solid black 1px;
    border-radius: 5%;
    padding: 0 0.5rem;
    margin-right: 2rem;
    cursor: pointer;
    ${(props) => props.theme.mobile`
        margin-right: 0.5rem;
        width:45%;
    `}
`;

export const SlideButtonRight = styled.div`
    position: relative;
    width: 30%;
    font-size: 1rem;
    height: 1.5rem;
    border: solid black 1px;
    border-radius: 5%;
    padding: 0 0.5rem;
    margin-left: 2rem;
    cursor: pointer;
    ${(props) => props.theme.mobile`
        margin-left: 0.5rem;
        width:45%;
    `}
`;