import styled from 'styled-components';
//import { ReactComponent as ManagerSVG} from '../../assets/manager.svg';


export const Container= styled.div`
    position: relative;
    height: 120vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.7rem;
    background-color: ${props => props.theme.colors.third};
    ${(props) => props.theme.mobile`
        height: 170vh;
   `}
       ${(props) => props.theme.smallMobile`
        height: 220vh;
   `}
`;

export const ServicesContainer = styled.div`
    position: relative;
    height: 80%;
    margin-top: 1.2rem;
    z-index: 8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 0.4;
    ${(props) => props.theme.mobile`
        margin-left:5%;
        width: 90%;
   `}
`;

export const BigContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: ${props => props.theme.colors.third};
    ${(props) => props.theme.mobile`
        flex-direction: column;
   `}
`;

// export const ManagerSvg = styled(ManagerSVG)`
//   flex: 0.2;
//   margin: 0;
//   ${(props) => props.theme.mobile`
//         visibility : hidden;
//    `}
//    ${(props) => props.theme.tablet`
//         visibility : hidden;
//    `}
// `;