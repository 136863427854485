import React from 'react';
import { Redirect, Switch, Route} from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MUItheme, Theme} from './theme.jsx';

import './App.css';

import HomePage from './pages/homepage/homepage.component';
import Header from './components/header/header.component';
import NotFoundPage from './components/not-found-page/not-found-page.component';

function App({loaded}) {

  return (
    <Theme>
      <ThemeProvider theme={MUItheme} >
        <CssBaseline />
        <div className="App" >
          <Header />
          <Switch>
            <Route exact path='/' render={() => (<HomePage loaded={loaded}/>)} />
            <Route path="/404" component={NotFoundPage} />
            <Redirect to="/404" />
          </Switch>
        </div>
      </ThemeProvider>
    </Theme>
  );
}

export default App;
