import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from "react-intersection-observer";

import { Container, ColumnContainer, RowContainer} from "./section-contact.styles";
import SectionHeader from "../../components/sectionHeader/sectionHeader.component";
import ContactInfos from "../../components/contact-infos/contact-infos.component";
import ContactForm from "../../components/contact-form/contact-form.component";
import loadLeaflet from '../../scripts/loadLeaflet';
import SimpleMap from '../../components/map/map.component'

import { useMediaQuery } from 'react-responsive';



const SectionContact = () => {
    const { t} = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [ref, inView] = useInView({triggerOnce: true, threshold: 0});
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
      if (inView && !isMobile) {
        loadLeaflet(() => {
            setScriptLoaded(true);
            console.log("hey!")
          });
    }}, [inView, isMobile]);
    
    return (
        <Container id="sectionContact" isMobile={isMobile} ref={ref}>
            <SectionHeader title={t("sections.contact.titre")} subtitle={t("sections.contact.sous_titre")}/> 
            <ColumnContainer>
                <RowContainer>
                    <ContactInfos />
                    {!isMobile && scriptLoaded && (<SimpleMap />)}
                </RowContainer>
                <ContactForm /> 
            </ColumnContainer>
        </Container>
    )
};

export default SectionContact;