import styled from 'styled-components';

export const Container= styled.div`
    position: relative;
    margin-top: -1.2rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    ${(props) => props.theme.mobile`
        margin-top: 0rem;
        flex-direction: row;
        width: 80%;
        justify-content: center;
   `}
    
`;

export const Text = styled.h3`
    width: 50%;
    font-size: 1.2rem;
    margin-left: auto;
    margin-right: auto;
    color: whitesmoke;
    ${(props) => props.theme.mobile`
        margin-top: auto;
        margin-bottom: auto;
   `}
`;

export const Icons = styled.div`
    width: 50%;
    margin-left: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    ${(props) => props.theme.mobile`
        width: 60%;
        margin-left: auto;
   `}
`;

export const Icon = styled.img`
    width: 3rem;
`;


