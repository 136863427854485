import styled from 'styled-components';

export const Container= styled.div`
    height: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${props => props.theme.colors.fourth};
    z-index: 10;
    .text {
        position:absolute;
    }
    .cursor {
        position: absolute;
        display: block;
        width:6px; /* controls thickness */
        overflow:hidden;
    }
    .char {
        visibility: hidden;
        color: ${props => props.theme.colors.fourth};
    }
`;

export const Line= styled.div`
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const FixedText = styled.div`
    width: 15%;
    font-size: 2.5rem;
    text-align: right;
    color: ${props => props.theme.colors.fourth};

`;

export const Text = styled.div`
    visibility:hidden; 
    position: absolute;
    text-align: left;
    height: 100%;
    width: 100%;
    font-size: 1.5rem;
    padding-top: 1.1rem;
`;

export const ChangingTextContainer = styled.div`
    height: 100%;
    width: 88%;
    margin-left: 10px;
`;


