import styled from 'styled-components';

export const Container= styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    ${(props) => props.theme.mobile`
        height: 220vh;
   `}
`;

export const ColumnContainer = styled.div`
    position: relative;
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    justify-content: space-evenly;
    ${(props) => props.theme.mobile`
        flex-direction: column-reverse;
        
   `}
`;
export const RowContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

