import styled, {css} from 'styled-components';

export const Container= styled.div`
    position: relative;
    flex: 1;
    margin-top: auto;
    margin-bottom: auto;
    height: 90%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border: solid ${props => props.theme.colors.third};
    background-color: ${props => props.theme.colors.third};
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(40,117,142,1) 49%, rgba(116,197,224,1) 100%);
    border-radius: 0.4rem;
`;

export const BeforeStarting = styled.div`
    position: relative;
    height: 80%;
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
`;

export const FormDescription = styled.p`
    position: relative;
    margin-top: 1rem;
    height: 60%;
    width: 100%;
    margin-bottom: 1rem;
    text-align: left;
    color: white;
    font-size: 1rem;
    ${(props) => props.theme.mobile`
        font-size: 0.85rem;
    `}
`;

export const BeginButton = styled.button`
    position: relative;
    height: 3rem;
    width: 8rem;
    border: solid #74c5e0;
    border-radius: 5%;
    background-color: white;
    font-size: 1rem;
    cursor: pointer;
`;

export const Form = styled.div`
    position: relative;
    height: 80%;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.h3`
    position: relative;
    margin: 0;
    height: 15%;
    width: 100%;
    font-size: 1.2rem;
    color: white;
`;

export const Options = styled.div`
    position: relative;
    height: 75%;
    width: 100%;
    margin-top: 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

export const Option = styled.button`
    position: relative;
    background-color: white;
    width: 80%;
    border: solid #74c5e0; 
    border-radius: 0.2rem;
    padding-bottom: 0.1rem;
    padding-top: 0.1rem;
    margin-bottom: 0.3rem;
    font-size: 1rem;
    outline: none;
    transition: transform 0.3s,color 0.3s, background-color 0.3s;
    cursor: pointer;

    ${props => (props.selected) && css`
        background-color: rgba(0,0,0,0);
        color: white;
    `}

    :hover{
        transform: scale(1.1);
        background-color: #b6e2f0;
    }
`;

export const Navigation = styled.div`
    position: relative;
    height: 10%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1rem;
`;

export const SlideButtonLeft = styled.div`
    position: relative;
    height: 80%;
    color: white;
    cursor: pointer;
`;

export const SlideButtonRight = styled.div`
    position: relative;
    height: 80%;
    color: white;
    cursor: pointer;
`;

export const Result = styled.div`
    position: relative;
    height: 80%;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
`;

export const ResultText = styled.div`
    position: relative;
    height: 70%;
    width: 100%;
    text-align: left;
    margin-bottom: 1rem;
    color: white;
    font-size: 1rem;
`;

export const RestartButton = styled.button`
    height: 3rem;
    border: solid ${props => props.theme.colors.third};
    border-radius: 5%;
    background-color: white;
    font-size: 1rem;
    cursor: pointer;
`;