import styled, {css} from "styled-components";
import FormControl from '@material-ui/core/FormControl';

//import { ReactComponent as LogoSVG} from '../../assets/logo.svg';

export const Logo = styled.div`
    margin-top: 0.35rem;
    font-size: 1.7rem;
    color: white;
    padding: 0 0.5rem ;
    border: solid white 0.2rem;
    border-radius: 10%;
`;

export const HeaderContainer = styled.div`
    position: fixed;
    top: 2rem;
    height: 3.5rem;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0);
    font-size: 0.5rem;
    color: white;
    opacity: 1;
    transition: background-color 1s, top 1s, color 1s, opacity 1s;

    ${props => props.atTheTop === false && css`
        background-color : rgba(255, 255, 255, 1);
        top: 0px;
        color: black;
        box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
        opacity: 1;

        ${Logo} {
            color: black;
            border: solid black 0.2rem;        
        }
    `}
`;

export const LogoContainer = styled.div`
    height: 100%;
    margin-left: 1rem;
    opacity: 1;
`;

export const Divider = styled.div`
    height: 4px;
    width: 50px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    background-color: ${props => props.theme.colors.second};
`;

export const MyFormControl = styled(FormControl)`
    ${(props) => props.theme.mobile`
        margin-top: 2rem;
        margin-right: auto;
        margin-left: auto;
    `}
`;