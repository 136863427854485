import styled from 'styled-components';

import { ReactComponent as PriceCalculatorSVG} from '../../assets/calculator.svg';

export const Container= styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.colors.third};
`;

export const TwoColumnContainer = styled.div`
    position: relative;
    width: 90%;
    height: 70%;
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    justify-content: space-evenly;
`;

export const PriceCalculatorSvg = styled(PriceCalculatorSVG)`
  flex: 0.8;
  margin-left: 5%;
`;