import styled, {css} from "styled-components";

export const OptionsContainer = styled.div`
    width: 50vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    opacity: 1;
    ${({theme}) => theme.laptop`
        width: 60vw;
   `}
    ${({theme}) => theme.tablet`
        width: 70vw;
        font-size: 0.9rem;
   `}
    .optionLink {
            text-decoration:none;
            color: inherit;
            cursor: pointer;
            :after {    
                background: none repeat scroll 0 0 transparent;
                bottom: 0;
                content: "";
                margin-left: 50%;
                display: block;
                height: 2px;
                
                background: #fff;
                transition: width 0.3s ease 0s, margin-left 0.3s ease 0s;
                width: 0;
            }
            :hover:after { 
                width: 100%;
                margin-left: 0%;
            }
    }
    
    ${props => props.orientation === 'vertical' && css`
        margin-top: 2rem;
        flex-direction: column;
        width: 80vw;

        .optionLink {
            margin-bottom: 1rem;
        }
    `}
`;