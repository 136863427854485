import React, {forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, BeforeStarting, FormDescription, BeginButton, RestartButton ,Form, Options, Title, Navigation, Option, Result, SlideButtonLeft, SlideButtonRight, ResultText} from "./price-form.styles.jsx";

const UserForm = forwardRef((props, ref) => {
    const { t} = useTranslation();
    //const text = t("sections.pricing.form_titres", { returnObjects: true });
    const formCategories = [
        {
            // t(`sections.pricing.form_titres.0`)
            index: 0,
            type: "radio",
            title: t("sections.pricing.form_titres.0"),
            options: [
                {name: t("sections.pricing.form_options.0.0"), price: 1000, selected: false},
                {name: t("sections.pricing.form_options.0.1"), price: 1500},
                {name: t("sections.pricing.form_options.0.2"), price: 2000},
                {name: t("sections.pricing.form_options.0.3"), price: 5000}
            ]
        },
        {
            index: 1,
            type: "radio",
            title: t("sections.pricing.form_titres.1"),
            options: [
                {name: t("sections.pricing.form_options.1.0"), price: 0},
                {name: t("sections.pricing.form_options.1.1"), price: 500},
                {name: t("sections.pricing.form_options.1.2"), price: 1000}
            ]
        },
        {
            index: 2,
            type: "radio",
            title: t("sections.pricing.form_titres.2"),
            options: [
                {name: t("sections.pricing.form_options.2.0"), price: 0},
                {name: t("sections.pricing.form_options.2.1"), price: 500},
                {name: t("sections.pricing.form_options.2.2"), price: 1000}
            ]
        },
        {
            index: 3,
            type: "radio",
            title: t("sections.pricing.form_titres.3"),
            options: [
                {name: t("sections.pricing.form_options.3.0"), price: 0},
                {name: t("sections.pricing.form_options.3.1"), price: 800},
                {name: t("sections.pricing.form_options.3.2"), price: 1500}
            ]
        },
        {
            index: 4,
            type: "radio",
            title: t("sections.pricing.form_titres.4"),
            options: [
                {name: t("sections.pricing.form_options.4.0"), price: 0},
                {name: t("sections.pricing.form_options.4.1"), price: 500},
                {name: t("sections.pricing.form_options.4.2"), price: 500}
            ]
        },
        {
            index: 5,
            type: "radio",
            title: t("sections.pricing.form_titres.5"),
            options: [
                {name: t("sections.pricing.form_options.5.0"), price: 0},
                {name: t("sections.pricing.form_options.5.1"), price: 200},
                {name: t("sections.pricing.form_options.5.2"), price: 500}
            ]
        },
        {
            index: 6,
            type: "radio",
            title: t("sections.pricing.form_titres.6"),
            options: [
                {name: t("sections.pricing.form_options.6.0"), price: 0},
                {name: t("sections.pricing.form_options.6.1"), price: 500},
            ]
        },
        {
            index: 7,
            type: "radio",
            title: t("sections.pricing.form_titres.7"),
            options: [
                {name: t("sections.pricing.form_options.7.0"), price: 0},
                {name: t("sections.pricing.form_options.7.1"), price: 500},
            ]
        },
        {
            index: 8,
            type: "select",
            title: t("sections.pricing.form_titres.8"),
            options: [
                {name: t("sections.pricing.form_options.8.0"), price: 300},
                {name: t("sections.pricing.form_options.8.1"), price: 300},
                {name: t("sections.pricing.form_options.8.2"), price: 300},
                {name: t("sections.pricing.form_options.8.3"), price: 300},
                {name: t("sections.pricing.form_options.8.4"), price: 500},
            ]
        },
        {
            index: 9,
            type: "radio",
            title: t("sections.pricing.form_titres.9"),
            options: [
                {name: t("sections.pricing.form_options.9.0"), price: 0},
                {name: t("sections.pricing.form_options.9.1"), price: 200},
                {name: t("sections.pricing.form_options.9.2"), price: 500},
            ]
        },
        {
            index: 10,
            type: "radio",
            title: t("sections.pricing.form_titres.10"),
            options: [
                {name: t("sections.pricing.form_options.10.0"), price: 0},
                {name: t("sections.pricing.form_options.10.1"), price: 200},
                {name: t("sections.pricing.form_options.10.2"), price: 500}
            ]
        }
    ];
    const categoriesLength = formCategories.length;

    var falseArray = [];
    formCategories.forEach((category, index) => {
        falseArray.push([])
        category.options.forEach((option, indexOption) => {
            falseArray[index].push(false)
        })
    });

    const [step, setStep] = useState(-1);
    const [stepToAvoid, setStepToAvoid] = useState(-1);
    const [selectedOptions, setSelectedOptions] = useState(falseArray);
    const [price, setPrice] = useState(0)

    //to force rerender of option
    const [click, setClick] = useState(0);

    const paginate = direction => {
        if(step===(stepToAvoid-direction)){
            setStep(step+direction*2);
        } else {
            setStep(step+direction);
        }
        if(step === categoriesLength-1) {
            var finalPrice = 0;
            selectedOptions.forEach((options, index) => {
                options.forEach((option, indexOption) => {
                    if(option) {
                        finalPrice+= formCategories[index].options[indexOption].price
                    }
                })
            })
            setPrice(finalPrice);
        }
    };

    const handleChange = (step, indexOption) => {
        const newSelectedOptions = selectedOptions;
        if(formCategories[step].type==="radio") {
            newSelectedOptions[step] = newSelectedOptions[step].map((option) => false);
        }
        newSelectedOptions[step][indexOption] = !selectedOptions[step][indexOption];
        setSelectedOptions(newSelectedOptions);
        setClick(click+1);
        // ce if permet de sauter le nombre d'objets à vendre si non e-commerce
        if(!selectedOptions[0][2]) {
            setStepToAvoid(5);
        } else {
            setStepToAvoid(-1);
        }
        if(formCategories[step].type==="radio" ) {
            paginate(1);
        }
        if(step === 0) {
            setPrice(0);
        }
    };

    return(
        <Container ref={ref}>
            {step===-1 && 
            (
            <BeforeStarting>
                <FormDescription>
                    {t("sections.pricing.form_description")}
                </FormDescription>
                <BeginButton onClick={() => paginate(1)}>
                    {t("sections.pricing.bouton_debut")}
                </BeginButton>
            </BeforeStarting>
            )}
            {step>=0 && step < categoriesLength &&
            (
            <Form step={step}>
                <Title>
                    {formCategories[step].title}
                </Title>
                <Options click={click} >
                    {formCategories[step].options.map((option, indexOption) => (
                        <Option key={indexOption} selected={selectedOptions[step][indexOption]} onClick={() => handleChange(step, indexOption)}>
                            {option.name}
                        </Option>
                    ))}
                </Options>
                <Navigation>
                    <SlideButtonLeft style={{visibility: step===0 ? "hidden": "visible"}} onClick={() => paginate(-1)}>&#10094;</SlideButtonLeft>
                    <SlideButtonRight onClick={() => paginate(1)}>&#10095;</SlideButtonRight>
                </Navigation>
            </Form>
            )}
            {step=== categoriesLength && 
            (
            <Result>
                <ResultText>
                    {t("sections.pricing.form_conclusion.0")} <b>{price}</b> {t("sections.pricing.form_conclusion.1")} 
                </ResultText>
                <RestartButton onClick={() => paginate(-categoriesLength)}>
                    {t("sections.pricing.bouton_recommencer")}
                </RestartButton>
            </Result>
            )}
        </Container>
    );
});

export default UserForm;