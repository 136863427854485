import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Mobile, Default } from '../../utils/media-queries/media-queries';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { changeLanguage } from '../../redux/language/language.actions';
import { selectTheLanguage } from '../../redux/language/language.selectors';

import { HeaderContainer, LogoContainer, Logo, Divider, MyFormControl } from './header.styles';
import NavigationLinks from "../navigation-links/navigation-links.component";

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Select from '@material-ui/core/Select';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
    menuButton: {
      marginRight: "2rem",
    },
    hide: {
        display: 'none',
      },
    drawer: {
        width: "200px",
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    select: {
        color: "inherit",
        marginRight: "2rem",
        zIndex: 100,
    },
    menuItem: {
        zIndex: 100,
    }
}));

const Header = ({language, changeLanguage}) => {

    //const [language,setLanguage] = useState('fr');
    const [onTop,setOnTop] = useState(true);

    const { i18n } = useTranslation();

    // useEffect(() => {
    //     const data = localStorage.getItem("language");
    //     if(data) {
    //         setLanguage(data);
    //     }
    // }, []);

    // useEffect(() => {
    //     localStorage.setItem("language", language);
    // });

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleChange = (e) => {
        //setLanguage(e.target.value);
        i18n.changeLanguage(e.target.value);
        changeLanguage(e.target.value);
        setOpen(false);
    }

    const toggleDrawer = (open) => (event) => {
        setOpen(open);
      };


    const myfunction = (open) => {
        setOpen(open);
    }

      useEffect(() => { 
        window.onscroll = function() {
            if(window.pageYOffset === 0) {
                setOnTop(true)
            }
            else{
                setOnTop(false)
            }
        }
        });

    return (
        <HeaderContainer atTheTop={onTop}>
            <LogoContainer>
                <Logo>
                    BCD
                </Logo>
            </LogoContainer>
            <Mobile>
                <IconButton
                    color={onTop ? "inherit" : "default"}
                    aria-label="open drawer"
                    onClick={toggleDrawer(true)}
                    edge="start"
                    className={clsx(classes.menuButton, open && classes.hide)}
                >
                    <MenuIcon />
                </IconButton>
                <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                    <NavigationLinks handleNavigationClick={myfunction} orientation="vertical" color="black"/>
                    <Divider/>
                    <MyFormControl variant="outlined" >
                    <Select
                        className={classes.select}
                        value={language}
                        onChange={handleChange}
                    >
                        <MenuItem className={classes.menuItem} value="fr">Français</MenuItem>
                        <MenuItem className={classes.menuItem} value="en">English</MenuItem>
                        <MenuItem className={classes.menuItem} value="es">Español</MenuItem>
                    </Select>
                </MyFormControl>
                </Drawer>
            </Mobile>
            <Default>
                <NavigationLinks />
                <MyFormControl>
                    <Select
                        className={classes.select}
                        value={language}
                        onChange={handleChange}
                    >
                        <MenuItem className={classes.menuItem} value="fr">Français</MenuItem>
                        <MenuItem className={classes.menuItem} value="en">English</MenuItem>
                        <MenuItem className={classes.menuItem} value="es">Español</MenuItem>
                    </Select>
                </MyFormControl>
            </Default>
        </HeaderContainer>
    )
};

const mapDispatchToProps = dispatch => ({
    changeLanguage: language => dispatch(changeLanguage(language))
  });
  
  const mapStateToProps = createStructuredSelector({
    language: selectTheLanguage
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header);
