import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    width: 100vw;
    height: 40vh;
    margin-top: 5rem;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(40,117,142,1) 49%, rgba(116,197,224,1) 100%);
    ${(props) => props.theme.mobile`
        margin-top: 0rem;
   `}
`;

export const SectionInfos = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.h2`
    margin: 0;
    font-size: 2.3rem;
    text-align: center;
    color: white;
    ${(props) => props.theme.mobile`
        font-size: 1.5rem;
   `}
`;

export const Subtitle = styled.h3`
    margin: 0;
    font-size: 1.5rem;
    text-align: center;
    color: white;
    opacity: 0.8;
    ${(props) => props.theme.mobile`
        font-size: 1rem;
   `}
`;