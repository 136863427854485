import React, { useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectTheLanguage } from '../../redux/language/language.selectors';

import { gsap } from "gsap";
import TextPlugin from "gsap/TextPlugin";
import SplitText from "gsap/SplitText";
import Physics2DPlugin from "gsap/Physics2DPlugin";

import { Default, Mobile } from '../../utils/media-queries/media-queries';
import ChangingText from '../../components/changing-text/changing-text.component'
import img1 from '../../assets/computer-background.jpg';

import { HomePageContainer, BackgroundImage, TitleAndSubtitle ,Title, TitleContainer, Maj, Subtitle, Texts } from './section-landing-animation.styles';

gsap.registerPlugin(TextPlugin, SplitText, Physics2DPlugin);

const SectionLandingAnimation = ({language, loaded}) => {

    const { t } = useTranslation();
    const [languageJustChanged, setLanguageJustChanged] = useState(false);
    //const [isMounted, setIsMounted] = useState(false);


    useEffect(() => {
        setLanguageJustChanged(true)
      }, [language]);

    useEffect(() => {
        if(languageJustChanged===true){
            setTimeout(() => {
                setLanguageJustChanged(false)
            }, 100)
        }
      }, [languageJustChanged]);


    return (
        <HomePageContainer loaded={loaded} itemScope itemType ="https://schema.org/Service" >
        <BackgroundImage src={img1} alt="bureau avec ordinateur souris et smartphone"/>
            <TitleAndSubtitle>
                <Default>
                    <Title itemProp="brand">
                        <Maj>B</Maj>rian &#160;<Maj>C</Maj>harles &#160;<Maj>D</Maj>evelopment
                    </Title>
                </Default>
                <Mobile>
                    <TitleContainer itemProp="brand">
                        <Title>
                            <span style={{display: "block"}}><Maj>B</Maj>rian &#160;<Maj>C</Maj>harles</span> 
                            <span style={{display: "block", marginTop: "-1rem"}}><Maj>D</Maj>evelopment</span>
                        </Title>
                    </TitleContainer>
                </Mobile>
                <Subtitle itemProp="serviceType">{t("sections.landing.sous_titre")}</Subtitle>
            </TitleAndSubtitle>
            <Default>
                {!languageJustChanged && (
                    <Texts>
                        <ChangingText
                            fixedText1={t("sections.landing.je")}
                            changingText1={[
                                t("sections.landing.writer_je.0"),
                                t("sections.landing.writer_je.1"),
                                t("sections.landing.writer_je.2")
                            ]}
                            fixedText2={t("sections.landing.vous")} //"Vous&#160;: " 
                            changingText2={[
                                t("sections.landing.writer_vous.0"),
                                t("sections.landing.writer_vous.1"),
                                t("sections.landing.writer_vous.2")
                            ]} />
                    </Texts>
                )}
                <div className="custom">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 15" preserveAspectRatio="none">
                        <path d="M 0 0 C 0 6 22 6 60 3 L 60 15 L 0 15 L 0 0" className="shape"></path>
                    </svg>
                </div>
                <div className="customTriangle">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M649.97 0L550.03 0 599.91 54.12 649.97 0z" className="shapeTriangle"></path>
                    </svg>
                </div>
            </Default>
        </HomePageContainer>
    )
};
  
const mapStateToProps = createStructuredSelector({
    language: selectTheLanguage
});

export default connect(mapStateToProps)(SectionLandingAnimation);