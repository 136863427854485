import React, { useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next';

import { useInView } from "react-intersection-observer";
import { gsap } from "gsap";

import { Container,TwoColumnContainer, PriceCalculatorSvg } from "./section-pricing.styles.jsx";
import SectionHeader from "../../components/sectionHeader/sectionHeader.component";
import PriceForm from '../../components/price-form/price-form.component.jsx';

import { Default } from "../../utils/media-queries/media-queries.jsx";



const SectionPricing = () => {
    const { t} = useTranslation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.4
    });
    const formRef = useRef(null);

    useEffect(() => {
          gsap.set(formRef.current, {autoAlpha:0, x:-300});
    }, []);

    useEffect(() => {
      if (inView) {
        gsap.to(formRef.current, {duration: 2,autoAlpha: 1,x:0,ease: "power2.out"});
    }}, [formRef, inView]);

    return (
        <Container id="sectionTarifs">
            <SectionHeader title={t("sections.pricing.titre")} subtitle={t("sections.pricing.sous_titre")}/> 
            <TwoColumnContainer ref={ref}>
                <PriceForm ref={formRef}/>
                <Default>
                    <PriceCalculatorSvg/>
                </Default> 
            </TwoColumnContainer>
        </Container>
    )
};

export default SectionPricing;