import { css } from 'styled-components'

const sizes = {
   smallMobile: [0,360],
    mobile: [0,767],
    tablet: [768,1023],
    laptop: [1024, 1599],
    desktop: [1600,10000]
 }
 
 export default Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media screen and (min-width: ${sizes[label][0]}px) and (max-width: ${sizes[label][1]}px) {
          ${css(...args)};
       }
    `
    return acc
 }, {})