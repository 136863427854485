import React, { useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next';

import { useInView } from "react-intersection-observer";
import { gsap } from "gsap";

import { Container, TwoRowContainer, Row, HumanProfileSvg, HikingSvg, Description, C} from "./section-a-propos.styles";
import SectionHeader from "../../components/sectionHeader/sectionHeader.component";

import { Desktop } from "../../utils/media-queries/media-queries.jsx";
import { useMediaQuery } from 'react-responsive';

const SectionAPropos = () => {
    const { t} = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const [ref1, inView1] = useInView({triggerOnce: true, threshold: 0.35});
    const [ref2, inView2] = useInView({triggerOnce: true, threshold: 0.35});
    const image1Ref = useRef(null);
    const image2Ref = useRef(null);

    useEffect(() => {
          gsap.set(image1Ref.current, {autoAlpha:0, x:300});
          gsap.set(image2Ref.current, {autoAlpha:0, x:-300});
    }, []);

    useEffect(() => {
      if (inView1) {
        gsap.to(image1Ref.current, {duration: 1,autoAlpha: 1,x:0,ease: "power2.out"});
    }}, [image1Ref, inView1]);

    useEffect(() => {
        if (inView2) {
          gsap.to(image2Ref.current, {duration: 1,autoAlpha: 1,x:0,ease: "power2.out"});
      }}, [image2Ref, inView2]);


    return (
        <Container id="sectionAPropos" isMobile={isMobile}>
            <SectionHeader title={t("sections.a_propos.titre")} subtitle={t("sections.a_propos.sous_titre")}/> 
            <TwoRowContainer itemScope itemType="https://schema.org/Person">
                <Row ref={ref1}>
                    <Description>
                        {t("sections.a_propos.descriptions.0.0")} <C itemProp="name">{t("sections.a_propos.descriptions.0.1")}</C>{t("sections.a_propos.descriptions.0.2")} <C itemProp="titleJob">{t("sections.a_propos.descriptions.0.3")}</C> {t("sections.a_propos.descriptions.0.4")}<C>{t("sections.a_propos.descriptions.0.5")}</C> {t("sections.a_propos.descriptions.0.6")}<C>{t("sections.a_propos.descriptions.0.7")}</C> {t("sections.a_propos.descriptions.0.8")}
                    </Description>
                    <Desktop>
                        <HumanProfileSvg ref={image1Ref}/>
                    </Desktop>
                </Row>
                <Row ref={ref2}>
                    <Desktop>
                        <HikingSvg ref={image2Ref} />
                    </Desktop>
                    <Description>
                    {t("sections.a_propos.descriptions.1.0")} <C>{t("sections.a_propos.descriptions.1.1")}</C>{t("sections.a_propos.descriptions.1.2")} <C>{t("sections.a_propos.descriptions.1.3")}</C> {t("sections.a_propos.descriptions.1.4")}<C>{t("sections.a_propos.descriptions.1.5")}</C> {t("sections.a_propos.descriptions.1.6")}<C>{t("sections.a_propos.descriptions.1.7")}</C> {t("sections.a_propos.descriptions.1.8")} <C>{t("sections.a_propos.descriptions.1.9")}</C>{t("sections.a_propos.descriptions.1.10")}<C>{t("sections.a_propos.descriptions.1.11")}</C> {t("sections.a_propos.descriptions.1.12")}
                    </Description>
                </Row>
            </TwoRowContainer>
        </Container>
    )
};

export default SectionAPropos;