import styled from 'styled-components';

export const Container= styled.div`
    position: relative;
    width: 100vw;
    height: 30vh;
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(40,117,142,1) 100%, rgba(116,197,224,1) 100%);
    display: flex;
    flex-direction: column;
    align-content: center;
    ${(props) => props.theme.mobile`
        height: 100vh;
   `}
`;

export const ColumnContainer = styled.div`
    position: relative;
    margin-top: 2rem;
    width: 90%;
    height: 17vh;
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    justify-content: space-evenly;
    ${(props) => props.theme.mobile`
        flex-direction: column;
        align-items: center;  
        height: 80vh;
   `}
`;
export const RowContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

export const TitleAndDescription = styled.div`
    position: relative;
    flex:1;
    height: 70%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Title = styled.h3`
    margin: 0;
    width: 90%;
    font-size: 1.2rem;
    color: white;
`;

export const Description = styled.p`
    width: 90%;
    font-size: 0.8rem;
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    color: whitesmoke;
`;

export const ContactInfos = styled.div`
    flex:1;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
`;

export const ContactInfo = styled.div`
    margin-top: -1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const Icon = styled.img`
    width: 1.5rem;
    filter: brightness(0) saturate(100%) invert(86%) sepia(71%) saturate(5306%) hue-rotate(182deg) brightness(120%) contrast(100%);
`;

export const Info = styled.p`
    margin-left: 1rem;
    font-size: 0.8rem;
    color: white;
    text-align: left;
`;

export const Divider = styled.div`
    height: 2px;
    width: 50%;
    margin-left: 25%;
    margin-top: 0.8rem;
    background-color: white;
`;

