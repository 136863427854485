import styled from 'styled-components';
import { ReactComponent as NotFoundSVG} from '../../assets/404.svg';


export const Container= styled.div`
    position: relative;
`;

export const NotFound = styled(NotFoundSVG)`
margin-top: 10vh;
  height: 70vh;
`;