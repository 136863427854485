import styled from 'styled-components';

export const Container= styled.div`
    position: relative;
    width: 50%;
    margin-left: 25%;
    height: 15vh;
    margin-top:2rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${(props) => props.theme.mobile`
        width: 80%;
        margin-left: 10%;
   `}


    /* #lines {
        visibility: hidden;
        width: 100%;
        height: 80%;
        filter: invert(79%) sepia(87%) saturate(2227%) hue-rotate(166deg) brightness(91%) contrast(92%);
    }

    .container {
        margin-top:20px;
        width: 100%;
        height: 5rem;
        display: flex;
        place-content: center;
        background: white;
    } */
`;


export const Title = styled.h2`
    margin: 0;
    color: ${props => props.theme.colors.fourth};
    text-align: center;
`;

export const Subtitle = styled.h3`
    margin: 0;
    font-size: 1rem;
    text-align: center;
`;

export const Divider = styled.div`
    height: 4px;
    width: 50px;
    margin-top: 10px;
    background-color: ${props => props.theme.colors.fourth};
`;