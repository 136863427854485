import React, {useState, useEffect, useRef} from 'react';

import { gsap } from "gsap";
import { MotionPathPlugin} from "gsap/MotionPathPlugin.js";
import {MorphSVGPlugin } from "gsap/MorphSVGPlugin.js";

import { Mobile, Default } from "../../utils/media-queries/media-queries.jsx";
import { useMediaQuery } from 'react-responsive';

import { Container, Navigation, SlideButtonLeft, SlideButtonRight} from './steps-animation.styles';
import { createRef } from 'react';

gsap.registerPlugin(MotionPathPlugin, MorphSVGPlugin);

const StepsAnimation = () => {

    const [isClickable, setIsClickable] = useState(true);
    const [step, setStep] = useState(0);

    const isMobile = useMediaQuery({ maxWidth: 767 })

    const textBoxRef = useRef(null);
    const circleRefPath = useRef(null);
    const textRefs = useRef([createRef(),createRef(),createRef(),createRef(),createRef(),createRef(),createRef()]);

    const OnAnimationStart = () => {
      setIsClickable(false);
      
    }; 
    useEffect(() => {
      if(isClickable===false){
        setTimeout(() => {setIsClickable(true)}, 1800);
      }
    }, [isClickable]);

    var tl = gsap.timeline({onStart: OnAnimationStart});

    useEffect(() => {
      textRefs.current.forEach((textRef, index) => {
        if(index!==0){
          gsap.set(textRef.current, {opacity:0});
        };
      });
    }, [isMobile]);

    const navigate = direction => {
      if(isClickable===true){ //pour eviter le click multiple
        const previousStep = step;
        var newStep = 0;
        if(step >= 1 && direction === -1) {
            newStep = step-1;
            setStep(newStep);
        }
        if(step <= 5 && direction === 1) {
          newStep = step+1;
          setStep(newStep);
        }
        if(isMobile){
          tl.to(textRefs.current[previousStep].current, { opacity: 0, duration: 0.2})
        .to(textBoxRef.current, { morphSVG: circleRefPath.current , duration: 0.6}, "-=0.2")
        .to(textBoxRef.current, { y: 20*(step+direction), duration: 0.6, ease: "expo"})
        .to(textBoxRef.current, { morphSVG: textBoxRef.current , duration: 0.6})
        .to(textRefs.current[newStep].current, { opacity: 1, duration: 0.4},"-=0.2");
        } else {
          tl.to(textRefs.current[previousStep].current, { opacity: 0, duration: 0.2})
        .to(textBoxRef.current, { morphSVG: circleRefPath.current , duration: 0.6}, "-=0.2")
        .to(textBoxRef.current, { x: 30*(step+direction), duration: 0.6, ease: "expo"})
        .to(textBoxRef.current, { morphSVG: textBoxRef.current , duration: 0.6})
        .to(textRefs.current[newStep].current, { opacity: 1, duration: 0.4},"-=0.2");
        }
    
      }
    };
    return (
        <Container isMobile={isMobile} >
          <Mobile isMobile={true}>
            <svg xmlns="http://www.w3.org/2000/svg" id="svg" viewBox="0 0 111 176" styles={{border: "solid red"}}>
              <g fill="#74c5e0" className="circle" >
                <path stroke="#74c5e0" d="M 4 28 A 1 1 0 0 0 14 28 A 1 1 0 0 0 4 28"/>
              </g>
              <g fill="#74c5e0" className="circle" >
                <path stroke="#74c5e0" d="M 4 48 A 1 1 0 0 0 14 48 A 1 1 0 0 0 4 48 "/>
              </g>
              <g fill="#74c5e0" className="circle" >
                <path stroke="#74c5e0" d="M 4 68 A 1 1 0 0 0 14 68 A 1 1 0 0 0 4 68"/>
              </g>
              <g fill="#74c5e0" className="circle" >
                <path stroke="#74c5e0" d="M 4 88 A 1 1 0 0 0 14 88 A 1 1 0 0 0 4 88 "/>
              </g>
              <g fill="#74c5e0" className="circle" >
                <path stroke="#74c5e0" d="M 4 108 A 1 1 0 0 0 14 108 A 1 1 0 0 0 4 108"/>
              </g>
              <g fill="#74c5e0" className="circle" >
                <path stroke="#74c5e0" d="M 4 128 A 1 1 0 0 0 14 128 A 1 1 0 0 0 4 128 "/>
              </g>
              <g fill="#74c5e0" className="circle" >
                <path stroke="#74c5e0" d="M 4 148 A 1 1 0 0 0 14 148 A 1 1 0 0 0 4 148"/>
              </g>

              <g fill="none" strokeWidth={2} className="line">
                <path stroke="#74c5e0" d="M 9 28 L 9 148 "/>
              </g>

                <path stroke="#74c5e0" fill="#74c5e0" strokeWidth={0.1} className="text-box" ref={textBoxRef} d="M 9 19 A 1 1 0 0 0 9 37 C 16 38 21 25 27 44 C 30 52 26 55 43 56 L 105 56 Q 111 56 111 50 L 111 6 Q 111 0 105 0 L 43 0 C 26 1 30 4 27 11 C 21 30 16 18 9 19"/>

                <path fill="#74c5e0" strokeWidth={0.1} className="big-circle" ref={circleRefPath} stroke="#74c5e0" d="M 0 28 A 1 1 0 0 0 18 28 A 1 1 0 0 0 0 28 "/>

              <g className="text" ref={textRefs.current[0]}>
                <text x="33" y="10" fontSize={4.7}>
                  <tspan x="33" dy="5"fontSize="6">Etude de projet</tspan>
                  <tspan x="33" dy="5" >Longue discussion par skype</tspan>
                  <tspan x="33" dy="5">ou en direct si vous résidez à</tspan>
                  <tspan x="33" dy="5">moins de 2h de route de chez moi</tspan>
                  <tspan x="33" dy="5">afin de discuter de votre projet,</tspan>
                  <tspan x="33" dy="5">de vos attentes, et de la manière</tspan>
                  <tspan x="33" dy="5">dont nous avancerons ensemble.</tspan>
                </text>
              </g>

              <g className="text" ref={textRefs.current[1]}>
              <text x="33" y="27" fontSize={4.7}>
                <tspan x="33" dy="5" fill="white" fontSize="6">Cahier des charges</tspan>
                <tspan x="33" dy="5" >Il est très important de poser</tspan>
                <tspan x="33" dy="5">rigoureusement sur papier ce </tspan>
                <tspan x="33" dy="5">qu'il a été convenu ainsi que</tspan>
                <tspan x="33" dy="5">les grandes lignes du design </tspan>
                <tspan x="33" dy="5">retenu afin de pouvoir</tspan>
                <tspan x="33" dy="5">avancer rapidement et</tspan>
                <tspan x="33" dy="5">sereinement sur le projet.</tspan>
              </text>
              </g>

              <g className="text" ref={textRefs.current[2]}>
              <text x="33" y="47" fontSize={4.7}>
                <tspan x="33" dy="5" fontSize="6">Conception maquettes</tspan>
                <tspan x="33" dy="5" >Pour chaque page importante</tspan>
                <tspan x="33" dy="5">je réalise une maquette évolutive</tspan>
                <tspan x="33" dy="5">en noir et blanc afin que nous</tspan>
                <tspan x="33" dy="5">puissions nous mettre d'accord</tspan>
                <tspan x="33" dy="5">sur l'aspect et le contenu de</tspan>
                <tspan x="33" dy="5">votre futur site web.</tspan>
              </text>
              </g>

              <g className="text" ref={textRefs.current[3]}>
              <text x="33" y="67" fontSize={4.7}>
                <tspan x="33" dy="5" fontSize="6">Développement</tspan>
                <tspan x="33" dy="5" >C'est ici qu'opère la magie,</tspan>
                <tspan x="33" dy="5">grâce aux dernières technologies</tspan>
                <tspan x="33" dy="5">du web je fais prendre vie aux</tspan>
                <tspan x="33" dy="5">maquettes et anime ces dernières</tspan>
                <tspan x="33" dy="5">pour une expérience utilisateur</tspan>
                <tspan x="33" dy="5">optimale.</tspan>
              </text>
              </g>

              <g className="text" ref={textRefs.current[4]}>
              <text x="33" y="87" fontSize={4.7}>
                <tspan x="33" dy="5" fontSize="6">Mise en ligne</tspan>
                <tspan x="33" dy="5" >Nous choisissons un hébergeur</tspan>
                <tspan x="33" dy="5">adapté au trafic escompté de</tspan>
                <tspan x="33" dy="5">votre site, un nom de domaine si</tspan>
                <tspan x="33" dy="5">vous n'en avez pas déjà un et</tspan>
                <tspan x="33" dy="5">je rends le site visible de tous!</tspan>
              </text>
              </g>

              <g className="text" ref={textRefs.current[5]}>
              <text x="33" y="105" fontSize={4.7}>
                <tspan x="33" dy="5" fontSize="6">Référencement</tspan>
                <tspan x="33" dy="5" >Bien que l'optimisation du site</tspan>
                <tspan x="33" dy="5">pour que celui ci arrive en bonne</tspan>
                <tspan x="33" dy="5">position lors d'une recherche de</tspan>
                <tspan x="33" dy="5">l'utilisateur se fasse pendant le</tspan>
                <tspan x="33" dy="5">développement, il y a encore de</tspan>
                <tspan x="33" dy="5">nombreuses choses à faire pour</tspan>
                <tspan x="33" dy="5">améliorer le référencement.</tspan>
              </text>
              </g>

              <g className="text" ref={textRefs.current[6]}>
              <text x="33" y="128" fontSize={4.7}>
                <tspan x="33" dy="5" fontSize="6">Maintenance</tspan>
                <tspan x="33" dy="5" >Il y a toujours des bugs</tspan>
                <tspan x="33" dy="5">des détails que l'on voudrait</tspan>
                <tspan x="33" dy="5">changer ou des fonctionnalités à</tspan>
                <tspan x="33" dy="5">ajouter, pour cela je m'occupe</tspan>
                <tspan x="33" dy="5">de maintenir votre site à un</tspan>
                <tspan x="33" dy="5">niveau d'utilisation optimal.</tspan>
              </text>
              </g>

                            
              <g className="numbers">
              <text x="7" y="30" fontSize={6}>
                <tspan x="7" dy="0">1</tspan>
                <tspan x="7" dy="20">2</tspan>
                <tspan x="7" dy="20">3</tspan>
                <tspan x="7" dy="20">4</tspan>
                <tspan x="7" dy="20">5</tspan>
                <tspan x="7" dy="20">6</tspan>
                <tspan x="7" dy="20">7</tspan>
              </text>
            </g>

            </svg>






          </Mobile>
            <Default isMobile={false}>
              <svg xmlns="http://www.w3.org/2000/svg" id="svg" viewBox="0 0 260 70" preserveAspectRatio="none" styles={{ border: "solid red" }}>
                <g fill="#74c5e0" className="circle" >
                  <path stroke="#74c5e0" d="M 25 10 A 1 1 0 0 0 35 10 A 1 1 0 0 0 25 10" />
                </g>
                <g fill="#74c5e0" className="circle" >
                  <path stroke="#74c5e0" d="M 55 10 A 1 1 0 0 0 65 10 A 1 1 0 0 0 55 10" />
                </g>
                <g fill="#74c5e0" className="circle" >
                  <path stroke="#74c5e0" d="M 85 10 A 1 1 0 0 0 95 10 A 1 1 0 0 0 85 10" />
                </g>
                <g fill="#74c5e0" className="circle" >
                  <path stroke="#74c5e0" d="M 115 10 A 1 1 0 0 0 125 10 A 1 1 0 0 0 115 10" />
                </g>
                <g fill="#74c5e0" className="circle" >
                  <path stroke="#74c5e0" d="M 145 10 A 1 1 0 0 0 155 10 A 1 1 0 0 0 145 10" />
                </g>
                <g fill="#74c5e0" className="circle" >
                  <path stroke="#74c5e0" d="M 175 10 A 1 1 0 0 0 185 10 A 1 1 0 0 0 175 10" />
                </g>
                <g fill="#74c5e0" className="circle" >
                  <path stroke="#74c5e0" d="M 205 10 A 1 1 0 0 0 215 10 A 1 1 0 0 0 205 10" />
                </g>

                <g fill="none" strokeWidth={2} className="line">
                  <path stroke="#74c5e0" d="M 30 10 L 210 10" />
                </g>

                <path stroke="#74c5e0" fill="#74c5e0" strokeWidth={0.1} className="text-box" ref={textBoxRef} d="M 39 10 A 1 1 0 0 0 21 10 C 21 17 26 17 25 21 C 23 28 0 22 0 40 L 0 60 Q 0 70 10 70 L 70 70 Q 80 70 80 60 L 80 40 C 80 20 37 28 35 21 C 34 17 39 17 39 10" />

                <path fill="#74c5e0" strokeWidth={0.1} className="big-circle" ref={circleRefPath} stroke="#74c5e0" d="M 21 10 A 1 1 0 0 0 39 10 A 1 1 0 0 0 21 10" />

                <g className="text" ref={textRefs.current[0]}>
                  <text x="10" y="30" fontSize={3.5}>
                    <tspan x="6" dy="5" fontSize="6">Etude de projet</tspan>
                    <tspan x="3" dy="5">Longue discussion par skype ou en</tspan>
                    <tspan x="3" dy="5">direct si vous résidez à moins de</tspan>
                    <tspan x="3" dy="5">2h de route de chez moi afin de</tspan>
                    <tspan x="3" dy="5">discuter de votre projet, de vos</tspan>
                    <tspan x="3" dy="5">attentes, et de la manière dont</tspan>
                    <tspan x="3" dy="5">nous avancerons ensemble.</tspan>
                  </text>
                </g>

                <g className="text" ref={textRefs.current[1]}>
                  <text x="40" y="30" fontSize={3.5}>
                    <tspan x="36" dy="5" fontSize="6">Cahier des charges</tspan>
                    <tspan x="33" dy="5" >Il est très important de poser</tspan>
                    <tspan x="33" dy="5">rigoureusement sur papier ce qu'il</tspan>
                    <tspan x="33" dy="5">a été convenu ainsi que les grandes</tspan>
                    <tspan x="33" dy="5">lignes du design retenu afin de</tspan>
                    <tspan x="33" dy="5">pouvoir avancer rapidement et</tspan>
                    <tspan x="33" dy="5">sereinement sur le projet.</tspan>
                  </text>
                </g>

                <g className="text" ref={textRefs.current[2]}>
                  <text x="70" y="30" fontSize={3.5}>
                    <tspan x="66" dy="5" fontSize="6">Conception maquettes</tspan>
                    <tspan x="63" dy="5" >Pour chaque page importante</tspan>
                    <tspan x="63" dy="5">je réalise une maquette évolutive</tspan>
                    <tspan x="63" dy="5">en noir et blanc afin que nous</tspan>
                    <tspan x="63" dy="5">puissions nous mettre d'accord</tspan>
                    <tspan x="63" dy="5">sur l'aspect et le contenu de</tspan>
                    <tspan x="63" dy="5">votre futur site web.</tspan>
                  </text>
                </g>

                <g className="text" ref={textRefs.current[3]}>
                  <text x="100" y="30" fontSize={3.5}>
                    <tspan x="96" dy="5" fontSize="6">Développement</tspan>
                    <tspan x="93" dy="5" >C'est ici qu'opère la magie, grâce</tspan>
                    <tspan x="93" dy="5">aux dernières technologies du web</tspan>
                    <tspan x="93" dy="5">je fais prendre vie aux maquettes</tspan>
                    <tspan x="93" dy="5">et anime ces dernières pour une</tspan>
                    <tspan x="93" dy="5">expérience utilisateur optimale.</tspan>
                  </text>
                </g>

                <g className="text" ref={textRefs.current[4]}>
                  <text x="130" y="30" fontSize={3.5}>
                    <tspan x="126" dy="5" fontSize="6">Mise en ligne</tspan>
                    <tspan x="123" dy="5" >Nous choisissons un hébergeur</tspan>
                    <tspan x="123" dy="5">adapté au trafic escompté de</tspan>
                    <tspan x="123" dy="5">votre site, un nom de domaine si</tspan>
                    <tspan x="123" dy="5">vous n'en avez pas déjà un et</tspan>
                    <tspan x="123" dy="5">je rends le site visible de tous!</tspan>
                  </text>
                </g>

                <g className="text" ref={textRefs.current[5]}>
                  <text x="160" y="30" fontSize={3.5}>
                    <tspan x="156" dy="5" fontSize="6">Référencement</tspan>
                    <tspan x="153" dy="5" >Une partie du référencement du</tspan>
                    <tspan x="153" dy="5">site se fait pendant le développement</tspan>
                    <tspan x="153" dy="5">mais il y a encore de très nombreuses</tspan>
                    <tspan x="153" dy="5">techniques à appliquer pour avoir un</tspan>
                    <tspan x="153" dy="5">site très bien classé par les moteurs</tspan>
                    <tspan x="153" dy="5">de recherche</tspan>
                  </text>
                </g>

                <g className="text" ref={textRefs.current[6]}>
                  <text x="190" y="30" fontSize={3.5}>
                    <tspan x="186" dy="5" fontSize="6">Maintenance</tspan>
                    <tspan x="183" dy="5" >Il y a toujours des bugs,</tspan>
                    <tspan x="183" dy="5">des détails que l'on voudrait</tspan>
                    <tspan x="183" dy="5">changer ou des fonctionnalités à</tspan>
                    <tspan x="183" dy="5">ajouter, pour cela je m'occupe</tspan>
                    <tspan x="183" dy="5">de maintenir votre site à un</tspan>
                    <tspan x="183" dy="5">niveau d'utilisation optimal.</tspan>
                  </text>
                </g>

                <g className="numbers">
              <text y="11" x="28" fontSize={5}>
                <tspan y="11" dx="0">1</tspan>
                <tspan y="11" dx="27.3">2</tspan>
                <tspan y="11" dx="27.3">3</tspan>
                <tspan y="11" dx="27.3">4</tspan>
                <tspan y="11" dx="27.3">5</tspan>
                <tspan y="11" dx="27.3">6</tspan>
                <tspan y="11" dx="27.3">7</tspan>
              </text>
              </g>

              </svg>
            </Default>
            <Navigation>
                <SlideButtonLeft style={{visibility: step===0 ? "hidden": "visible", opacity: isClickable ? "1": "0.5"}} onClick={() => navigate(-1, false)}>&#10094; précédent</SlideButtonLeft>
                <SlideButtonRight style={{visibility: step===6 ? "hidden": "visible", opacity: isClickable ? "1": "0.5"}} onClick={() => navigate(1, false)}>suivant &#10095;</SlideButtonRight>
            </Navigation>
        </Container>
    )
};

export default StepsAnimation;