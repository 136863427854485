import React, {useState, useEffect} from 'react';
import { Frame, useAnimation } from "framer";
import { useTranslation } from 'react-i18next';

import { useInView } from "react-intersection-observer";

import { Container, MultiColumnContainer, CompetenceBarContainer, ColumnContainer, Title, CodingSvg } from "./section-competences.styles.jsx";
import SectionHeader from "../../components/sectionHeader/sectionHeader.component";
import CompetenceBar from "../../components/competence-bar/competence-bar.component"
import { Desktop } from '../../utils/media-queries/media-queries.jsx';

const competencesDev = [
    {
        title: 'HTML5 - CSS3',
        percentage: "90%"
    },
    {
        title: 'JAVASCRIPT',
        percentage: "92%"
    },
    {
        title: 'REACT.JS - REDUX',
        percentage: "95%"
    },
    {
        title: 'ANIMATIONS',
        percentage: "90%"
    },
    {
        title: 'GRAPHISMES',
        percentage: "75%"
    },
    {
        title: 'MONGODB',
        percentage: "78%"
    },
    {
        title: 'FIREBASE',
        percentage: "80%"
    }
]

const competencesOthers = [
    {
        title: 'PHYSIQUE/MATHS',
        percentage: "95%"
    },
    {
        title: 'LANGUES ETRANGERES',
        percentage: "85%"
    },
    {
        title: 'CURIOSITE',
        percentage: "95%"
    },
    {
        title: 'CREATIVITE',
        percentage: "90%"
    },
    {
        title: 'PERSEVERANCE',
        percentage: "85%"
    },
    {
        title: "VITESSE APPRENTISSAGE",
        percentage: "95%"
    },
    {
        title: 'VULGARISATION',
        percentage: "95%"
    }
]

const competencesDevVariants = {
    hidden: {
        opacity: 0,
        y: 200,
        transition: {
            duration: 2
        },
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 2
        },
    }
}

const SectionCompetences = () => {
    const { t} = useTranslation();
    const controls1 = useAnimation();
    const controls2 = useAnimation();
    const [ref1, inView1] = useInView();
    const [ref2, inView2] = useInView();
    const [percentages1, setPercentages1] = useState(["0%","0%","0%","0%","0%","0%","0%"]);
    const [percentages2, setPercentages2] = useState(["0%","0%","0%","0%","0%","0%","0%"]);


    useEffect(() => {
      if (inView1) {
        var newPercentages = [];
        competencesDev.forEach((competenceDev) => {
            newPercentages.push(competenceDev.percentage)
        });
        setPercentages1(newPercentages);
        controls1.start("visible");
      }
      else {
      }
    }, [controls1, inView1]);

    useEffect(() => {
        if (inView2) {
            var newPercentages = [];
            competencesOthers.forEach((competenceOthers) => {
                newPercentages.push(competenceOthers.percentage)
            });
            setPercentages2(newPercentages);
            controls2.start("visible");
        }
      }, [controls2, inView2]);

    return (
        <Container id="sectionCompetences">
        <SectionHeader title={t("sections.mes_competences.titre")} subtitle={t("sections.mes_competences.sous_titre")}/> 
            <MultiColumnContainer itemScope itemType="https://schema.org/Person">
                <ColumnContainer>
                    <Title>
                        {t("sections.mes_competences.competences_titre.0")}
                    </Title>
                    <CompetenceBarContainer ref={ref1}>
                        <Frame
                        width={"100%"}
                        height={"100%"}
                        background={""}
                        variants={competencesDevVariants}
                        initial={"hidden"}
                        animate={controls2}
                        >
                        {competencesDev.map((competenceDev, index) => (
                            <CompetenceBar key={index} text={t(`sections.mes_competences.competences_dev.${index}`)} percentage={percentages1[index]} index={index}/>
                        ))}
                        </Frame>
                    </CompetenceBarContainer>
                </ColumnContainer>
                <Desktop>
                    <CodingSvg/>
                </Desktop>
                <ColumnContainer>
                    <Title>
                        {t("sections.mes_competences.competences_titre.1")}
                    </Title>
                    <CompetenceBarContainer ref={ref2}>
                        <Frame
                            width={"100%"}
                            height={"100%"}
                            background={""}
                            variants={competencesDevVariants}
                            initial={"hidden"}
                            animate={controls2}
                        >
                            {competencesOthers.map((competenceOthers, index) => (
                                <CompetenceBar key={index} text={t(`sections.mes_competences.competences_autres.${index}`)} percentage={percentages2[index]} index={index}/>
                            ))}
                        </Frame>
                    </CompetenceBarContainer>
                </ColumnContainer>
                
            </MultiColumnContainer>
        </Container>
    )
};

export default SectionCompetences;