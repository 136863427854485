import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    ${(props) => props.theme.mobile`
        flex:2;
   `}
    

    .form {
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .textbox {
        position: relative;
        display: flex;
        flex: 1;
        width: 80%;
        margin-bottom: 1rem;
        
    }

    .multiline {
        position: relative;
        display: flex;
        flex:3;
        width: 80%;
        
    }

    .button {
        position: relative;
        display: flex;
        flex:1;
        
    }
`;

export const MessageSended = styled.p`
    position: relative;
    width: 50%;
    left: 25%;
    color: ${(props)=> props.theme.colors.second};
    font-size: 1.5rem;
`;