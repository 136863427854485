import React, { useRef, useEffect} from 'react';
import { useTranslation } from 'react-i18next';

import { useInView } from "react-intersection-observer";
import { gsap } from "gsap";
import { Default } from "../../utils/media-queries/media-queries.jsx";

import { Container, TwoColumnContainer, DescriptionsContainer, DescriptionContainer, Title, Description, ThinkingMan } from "./section-pourquoi-moi.styles.jsx";
import SectionHeader from "../../components/sectionHeader/sectionHeader.component";
//import AnimatedThinkingBubble from '../../components/animated-thinking-bubble/animated-thinking-bubble.component'

// const descriptions = [
//     {
//         title: "PLUS QU UN SIMPLE INTEGRATEUR WEB, UN DEVELOPPEUR",
//         description: "Je ne me contente pas de gérer des templates prédéfinis via des CMS (éditeurs visuels de site) comme Wordpress ou autre, tout est codé à la main. Cela signifie que j'ai beaucoup plus de liberté pour la mise en page et les fonctionnalités. \n Avec des CMS plus le site augmente en taille avec le temps, plus il est difficile de faire des modifications, et plus il risque de ralentir. J'utilise React.js, la technologie aujourd'hui la plus aboutie pour créer un site web moderne. En choisissant un site construit ainsi vous vous assurez d'avoir une qualité et une maintenabilité optimales."
//     },
//     {
//         title: 'UN PROFESSIONNEL INDEPENDANT POUR MENER LE PROJET DE A à Z',
//         description: "Pourquoi faire confiance à un professionnel indépendant plutôt qu'à une agence ? On ne travaille jamais mieux que lorsque sa réputation est en jeu, je m'engage à mener votre projet du début à la fin du mieux possible. De plus la prestation est moins chère pour un même travail. \n Etant touche à tout je serai capable de m'adapter à votre projet et ses spécificités.",
//     }
// ]

const SectionPourquoiMoi = () => {
    const { t} = useTranslation();

    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.5
    });
    const imageRef = useRef(null);

    useEffect(() => {
          gsap.set(imageRef.current, {autoAlpha:0, y:300});
    }, []);

    useEffect(() => {
      if (inView) {
        gsap.to(imageRef.current, {duration: 1,autoAlpha: 1,y:0,ease: "power2.out"});
    }}, [imageRef, inView]);
  

    return (
        <Container ref={ref} >
            <SectionHeader 
                title={t(`sections.pourquoi_moi.titre`).toUpperCase()}
                subtitle={t(`sections.pourquoi_moi.sous_titre`)}
            /> 
            <TwoColumnContainer>
                <Default>
                    <ThinkingMan ref={imageRef}/>
                </Default>
                <DescriptionsContainer >
                    {[0,1].map( (value) => (
                        <DescriptionContainer key={value}>
                            <Title >{t(`sections.pourquoi_moi.raison_titre.${value}`).toUpperCase()}</Title>
                            <Description >{t(`sections.pourquoi_moi.raison_description.${value}`)}</Description>
                        </DescriptionContainer>
                    ))}
                </DescriptionsContainer>
            </TwoColumnContainer>
        </Container>
    )
};

export default SectionPourquoiMoi;