import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1.5;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .leaflet-container {
        height: 90%;
        width: 100%;
    }

`;