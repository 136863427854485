import React from 'react';

import { Container, Title, Subtitle, Divider } from './sectionHeader.styles';

const SectionHeader = ({title, subtitle}) => {


    return (
        <Container>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <Divider/>
        </Container>
    )
};

export default SectionHeader;