import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, ColumnContainer } from './contact-infos.styles';
import LocationIcon from '../../assets/location.svg';
import EmailIcon from '../../assets/mail.svg';
import PersonnalInfosIcon from '../../assets/personnal-infos.svg';
import PhoneIcon from '../../assets/phone.svg';

import InfosWithIcon from './../infos-with-icon/infos-with-icon.component';

const CompetenceBar = () => {
    const { t} = useTranslation();
    const contactInfos1 = [
        {
            title: t("sections.contact.contact.email").toUpperCase(),
            description: "briancharlesdev@gmail.com",
            icon: EmailIcon,
            altIcon: "icone e-mail",
            itemProp: "email"
        },
        {
            title: t("sections.contact.contact.telephone").toUpperCase(),
            description: "(+33)612093907",
            icon: PhoneIcon,
            altIcon: "icone téléphone",
            itemProp: "telephone"
        }
    ]

    const contactInfos2 = [
        {
            title: t("sections.contact.contact.siren").toUpperCase(),
            description: "821 328 721",
            icon: PersonnalInfosIcon,
            altIcon: "icone informations personnelles",
            itemProp: ""
        },
        {
            title: t("sections.contact.contact.adresse").toUpperCase(),
            description: "Les Itardes, 04700 Le Castellet, France",
            icon: LocationIcon,
            altIcon: "icone position géographique",
            itemProp: "address"
        }
    ]

    return (
        <Container>
            <ColumnContainer>
            {contactInfos1.map((contactInfo, index) => (
                    <InfosWithIcon
                        key={index}
                        position={"right"}
                        title={contactInfo.title}
                        description={contactInfo.description}
                        icon={contactInfo.icon}
                        circled={false}
                        perspective={false}
                        iconColored={false}
                        descriptionColor="grey"
                        iconSize="50"
                        distanceIconInfos="0.1rem"
                        altIcon={contactInfo.altIcon}
                        type="contact"
                        prop={contactInfo.itemProp}
                    />
            ))}
            </ColumnContainer>
            <ColumnContainer>
            {contactInfos2.map((contactInfo, index) => (
                    <InfosWithIcon
                        key={index}
                        position={"right"}
                        title={contactInfo.title}
                        description={contactInfo.description}
                        icon={contactInfo.icon}
                        circled={false}
                        perspective={false}
                        iconColored={false}
                        descriptionColor="grey"
                        iconSize="50"
                        distanceIconInfos="0.1rem"
                        altIcon={contactInfo.altIcon}
                        type="contact"
                        prop={contactInfo.itemProp}
                    />
            ))}
            </ColumnContainer>
        </Container>
    )
};

export default CompetenceBar;