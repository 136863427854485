import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Title, Subtitle, SectionInfos } from './section-imagination.styles';

const SectionImagination = () => {
    const { t} = useTranslation();
    return (
        <Container>
            <SectionInfos>
                <Title>
                    {t("sections.imagination.titre").toUpperCase()}
                </Title>
                <Subtitle>
                    {t("sections.imagination.sous_titre")}
                </Subtitle>
            </SectionInfos>
        </Container>
    )
};

export default SectionImagination;