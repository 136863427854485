import styled from 'styled-components';
import { ReactComponent as ThinkingManSVG} from '../../assets/thinking-man.svg';

export const Container= styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    ${(props) => props.theme.mobile`
        height: 160vh;
   `}
    ${(props) => props.theme.smallMobile`
        height: 200vh;
   `}
`;


export const TwoColumnContainer = styled.div`
    width: 90%;
    height: 70%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    justify-content: space-between;
`;

export const DescriptionsContainer = styled.div`
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    ${(props) => props.theme.mobile`
        width: 100%;
   `}
`;

export const DescriptionContainer = styled.div`
    position: relative;
    width: 100%;
    height: 90%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 30px 30px 50px ${({theme}) => theme.colors.third};
`;

export const Title = styled.h3`
    margin: 0;
    width: 90%;
    font-size: 1.2rem;
    color: ${({theme}) => theme.colors.second};
`;

export const Description = styled.p`
    width: 90%;
    font-size: 1.0rem;
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    color: black;
`;

export const ThinkingMan = styled(ThinkingManSVG)`
  width: 35%;
`;

