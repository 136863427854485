import React, { useEffect, useRef} from 'react';

import { gsap } from "gsap";
import SplitText from "gsap/SplitText";

import { Container, FixedText, Text, ChangingTextContainer, Line} from './changing-text.styles';
import { createRef } from 'react';

gsap.registerPlugin(SplitText);
gsap.config({
    nullTargetWarn: false
  });

const ChangingText = ({fixedText1, changingText1, fixedText2, changingText2}) => {

    const textRef = [useRef([createRef(), createRef(), createRef()]),useRef([createRef(), createRef(), createRef()])];
    const textContainerRef = [useRef([createRef(), createRef(), createRef()]),useRef([createRef(), createRef(), createRef()])];
    const cursorRef = [useRef([createRef(), createRef(), createRef()]),useRef([createRef(), createRef(), createRef()])];
    var master = gsap.timeline({repeat: -1});

    useEffect(() => { 
        setTimeout(() => {

        const revealInterval = 0.2, 
            blinkingStartRepeatNumber = 2, 
            blinkingEndRepeatNumber = 10, 
            blinkingTime = 6;

        function tl(index, i) {
            var mySplitText = new SplitText(textRef[index].current[i], {type:"chars", charsClass:"char", position:"absolute"}); 
            var chars = mySplitText.chars;
            var tl =  gsap.timeline({onComplete:function(){
                gsap.set(cursorRef[index].current[i], {opacity:0})
            }});
            tl.fromTo(cursorRef[index].current[i], blinkingTime, {opacity:0}, {opacity:1, repeat:blinkingStartRepeatNumber, ease: "steps(1)"}); // this makes the cursor resume blinking after reversing
            tl.set(cursorRef[index].current[i], {opacity:1, position:"absolute"}) // this makes it visible (not blinking) when playing forward
            var initialLeft = 0, initialTop=0;
            chars.forEach( (element, j) => {
                var offset = element.getBoundingClientRect();
                if(j===0){
                    initialLeft = offset.left;
                    initialTop = offset.top;
                }
                tl.set(cursorRef[index].current[i], {opacity: 1, left:offset.left + offset.width- initialLeft, top:offset.top + offset.height/2 - initialTop}, blinkingStartRepeatNumber*blinkingTime + (j +1) * revealInterval)
                tl.set(element, {autoAlpha :1}, blinkingStartRepeatNumber*blinkingTime + (j +1) * revealInterval) 
            });
            tl.fromTo(cursorRef[index].current[i], blinkingTime, {opacity:0}, {opacity:1, repeat:blinkingEndRepeatNumber, ease: "steps(1)"});
            tl.staggerTo(chars, 2, {autoAlpha:0, scale:0, ease:"back.out(1.7)"}, 0.01);
            gsap.set(textContainerRef[index].current[i], {visibility:"visible"});
            tl.duration(10);
            return tl;
            }

            master.add(tl(0,0))
                .add(tl(1,0),"<")
                .add(tl(0,1), "+=1")
                .add(tl(1,1), "<")
                .add(tl(0,2), "+=1")
                .add(tl(1,2), "<")
        
         }, 1000);
     });

     useEffect(() => {

        return () => {
          master.kill();
        }
      }, [master])

    return (
        <Container>
            <Line>
                <FixedText >{fixedText1}</FixedText>
                <ChangingTextContainer>
                    {[1, 2, 3].map((value, i) => (
                        <Text key={(i + 1) * 100} ref={el => textContainerRef[0].current[i] = el}>
                            <div key={(i + 1) * 200} ref={el => textRef[0].current[i] = el} className="text">{changingText1[i]}</div>
                            <span key={(i + 1) * 300} ref={el => cursorRef[0].current[i] = el} className="cursor">|</span>
                        </Text>
                    ))}
                </ChangingTextContainer>
            </Line>
            <Line>
                <FixedText >{fixedText2}</FixedText>
                <ChangingTextContainer>
                    {[1, 2, 3].map((value, i) => (
                        <Text key={(i + 1) * 100} ref={el => textContainerRef[1].current[i] = el}>
                            <div key={(i + 1) * 200} ref={el => textRef[1].current[i] = el} className="text">{changingText2[i]}</div>
                            <span key={(i + 1) * 300} ref={el => cursorRef[1].current[i] = el} className="cursor">|</span>
                        </Text>
                    ))}
                </ChangingTextContainer>
            </Line>
            
        </Container>
    )
}

export default ChangingText;