import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, EntrepreneurSvg, TwoColumnContainer } from "./section-deroulement.styles.jsx";

import { Default, Mobile } from "../../utils/media-queries/media-queries.jsx";
import { useMediaQuery } from 'react-responsive';

import SectionHeader from "../../components/sectionHeader/sectionHeader.component";
import StepsAnimation from '../../components/steps-animation/steps-animation.component.jsx';

const SectionDeroulement = () => {
    const { t} = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 })

    return (
        <Container isMobile={isMobile}>
            <SectionHeader title={t("sections.deroulement.titre")} subtitle={t("sections.deroulement.sous_titre")}/>
            <TwoColumnContainer>
                <Default>
                    <EntrepreneurSvg />
                    <StepsAnimation isMobile={isMobile}/>
                </Default>
                <Mobile>
                    <StepsAnimation isMobile={isMobile}/>
                </Mobile>
            </TwoColumnContainer> 
        </Container>
    )
};

export default SectionDeroulement;