import React from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useForm, Controller } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

import { Container, MessageSended} from './contact-form.styles';
import { useState } from 'react';

const ContactForm = () => {
    const { t} = useTranslation();
    const { handleSubmit, register, errors, control } = useForm();
    const [sended, setSended] = useState(false)

    const onSubmit = values => {
        axios
        .post(
            'https://us-central1-portfolio-bfa76.cloudfunctions.net/sendMail',
            values
        )
        .then(function(response) {
            console.log(response);
            setSended(true)

        })
        .catch(function(error) {
            console.log(error);
        });
    }
  
    return (
    <Container>
        {!sended ? (
            <form onSubmit={handleSubmit(onSubmit)} className="form">
    
                <Controller
                    as={TextField} 
                    name="PrenomNom" 
                    control={control}
                    defaultValue=""
    
                    error={errors.PrenomNom}
                    label={t("sections.contact.form_contact.prenom_nom")}
                    variant="outlined"
                    inputRef={register({ required: true })}
                    helperText={errors.PrenomNom && t("sections.contact.form_contact.aide_nom")}
                    inputProps={{style: {fontSize: "0.9rem"}}}
                    InputLabelProps={{style: {fontSize: "0.9rem"}}}
                    FormHelperTextProps={{style: {fontSize: "0.6rem"}}}
    
                    className="textbox"
                />
    
                <Controller
                    as={TextField} 
                    name="Email" 
                    control={control}
                    defaultValue=""
    
                    error={errors.Email}
                    label={t("sections.contact.form_contact.email")}
                    variant="outlined"
                    inputRef={register({ 
                        required: true,
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address"
                          }
                     })}
                    helperText={errors.Email && t("sections.contact.form_contact.aide_email")}
                    inputProps={{style: {fontSize: "0.9rem"}}}
                    InputLabelProps={{style: {fontSize: "0.9rem"}}}
                    FormHelperTextProps={{style: {fontSize: "0.6rem"}}}
    
                    className="textbox"
                />
    
                <Controller
                    as={TextField} 
                    name="Telephone" 
                    control={control}
                    defaultValue=""
    
                    error={errors.Telephone}
                    label={t("sections.contact.form_contact.telephone")}
                    variant="outlined"
                    inputRef={register({})}
                    inputProps={{style: {fontSize: "0.9rem"}}}
                    InputLabelProps={{style: {fontSize: "0.9rem"}}}
                    FormHelperTextProps={{style: {fontSize: "0.6rem"}}}
    
                    className="textbox"
                />
    
                <Controller
                    as={TextField} 
                    name="Projet" 
                    control={control}
                    defaultValue=""
    
                    error={errors.Projet}
                    label={t("sections.contact.form_contact.projet")}
                    variant="outlined"
                    multiline
                    rows={4}
                    inputRef={register({ required: true })}
                    helperText={errors.Projet && t("sections.contact.form_contact.aide_projet")}
                    inputProps={{style: {fontSize: "0.9rem"}}}
                    InputLabelProps={{style: {fontSize: "0.9rem"}}}
                    FormHelperTextProps={{style: {fontSize: "0.6rem"}}}
    
                    className="multiline"
                />
    
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    endIcon={<SendIcon />}
                    type="submit"
    
                    className="button"
                >
                    {t("sections.contact.form_contact.envoyer")}
                </Button>
    
            </form>
        ) : (
            <MessageSended>{t("sections.contact.form_contact.message_envoye")}</MessageSended>
        )}
    </Container>
      
    )
};

export default ContactForm;