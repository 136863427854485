import React from 'react';

import { Container, ColoredPart, TextPart } from './competence-bar.styles';

const CompetenceBar = ({text, percentage, index = 0, animated = true}) => {
    return (
        <Container animated={animated}>
            <ColoredPart percentage={percentage} index={index}>
                <TextPart itemProp="knowsAbout">
                    {text}
                </TextPart>
            </ColoredPart>
        </Container>
    )
};

export default CompetenceBar;