import styled from 'styled-components';

export const BackgroundImage = styled.img`
    position:absolute;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    filter: invert(20%) sepia(89%) saturate(212%) hue-rotate(149deg) brightness(100%) contrast(82%);
`;

export const Maj= styled.span`
    font-size: 6.5rem;
    margin-top: -2rem;
    ${(props) => props.theme.mobile`
        font-size: 4rem;
        margin-top: -1.3rem;
   `}
    ${(props) => props.theme.tablet`
        font-size: 4.8rem;
        margin-top: -1.6rem;
   `}
    ${(props) => props.theme.smallMobile`
        font-size: 3.6rem;
        margin-top: -1.1rem;
   `}
`;

export const TitleAndSubtitle = styled.div`
    position: relative;
    width: 80vw;
    left: 10vw;
    height: 20vh;
    top: 22vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    ${(props) => props.theme.mobile`
        top:28vh;
        height: 50vh;
   `}
`;

export const TitleContainer = styled.div`
    position: relative;
    height: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title= styled.h1`
    color: white;
    height: 10vh;
    display: flex;
    flex-direction: row;
    font-size: 3.5rem;
    margin:0;
    ${(props) => props.theme.mobile`
        font-size: 2.5rem;
        height: 7.5vh;
        flex-direction: column;
        width: 100vw;
   `}
    ${(props) => props.theme.smallMobile`
        font-size: 2.5rem;
   `}
    ${(props) => props.theme.tablet`
        font-size: 2.9rem;
   `}
`;

export const Subtitle= styled.h2`
    height: 7vh;
    font-size: 1.5rem;
    color: white;
    margin:0;
    ${(props) => props.theme.mobile`
        font-size: 1.5rem;
   `}
    ${(props) => props.theme.smallMobile`
        font-size: 1.3rem;
   `}
`;
//#005B9B

export const Texts = styled.div`
    position: absolute;
    width: 80%;
    top: 73vh;
    left: 7%;
    display: flex;
    flex-direction: column;
`;

export const Description= styled.div`
    position: absolute;
    font-size: 0.8rem;
    width: 60%;
    top: 50vh;
    left: 15%;
    text-align: left;
    color: white;
`;

export const HomePageContainer = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;

    .custom{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }

    .custom svg {
        position: relative;
        display: block;
        width: calc(300% + 1.3px);
        height: 42vh;
    }

    .custom .shape {
        fill: #FFFFFF;
    }

    .customTriangle{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
    }

    .customTriangle svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 10vh;
    }

    .customTriangle .shapeTriangle {
        fill: ${props => props.theme.colors.third};
    }

    ${Maj} {
        color: ${props => props.loaded ? props.theme.colors.fourth : "white"};
    }
`;