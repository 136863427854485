import React, {createRef ,useEffect, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import ComputerIconSVG from '../../assets/iconComputer.svg';
import ConceptionIconSVG from '../../assets/iconConception.svg';
import TranslationIconSVG from '../../assets/iconTranslation.svg';
import GraphicsIconSVG from '../../assets/iconGraphics.svg';
import LightBulbIconSVG from '../../assets/iconLightBulb.svg';
import MagnifyingGlassIconSVG from '../../assets/iconMagnifyingGlass.svg';
import MaintenanceIconSVG from '../../assets/iconMaintenance.svg';
import ResponsiveIconSVG from '../../assets/iconResponsive.svg';

import { useInView } from "react-intersection-observer";
import { useMediaQuery } from 'react-responsive';

//import useDimensions from "react-use-dimensions";
import { gsap } from "gsap";

import { Container, BigContainer, ServicesContainer} from "./section-mes-services.styles.jsx";
import SectionHeader from "../../components/sectionHeader/sectionHeader.component";
import InfosWithIcon from "../../components/infos-with-icon/infos-with-icon.component";

const services = [[
    {
        title: 'GESTION DE PROJETS WEB DE A à Z',
        description: "Accompagnement dans votre projet de site vitrine, e-commerce, web app, etc",
        icon: ConceptionIconSVG,
        altIcon: "icone conception"
    },
    {
        title: 'CONCEPTION SUR MESURE',
        description: "Pour être certain que votre site vous corresponde parfaitement",
        icon: ComputerIconSVG,
        altIcon: "icone ordinateur"
    },
    {
        title: 'CREATION DU DESIGN',
        description: "Des sites et contenus élégants et modernes qui raviront les utilisateurs",
        icon: GraphicsIconSVG,
        altIcon: "icone palette peinture"
    },
    {
        title: 'OPTIMISATION DU REFERENCEMENT',
        description: "Car un beau site web c'est bien, un beau site web visible de tous c'est mieux",
        icon: MagnifyingGlassIconSVG,
        altIcon: "icone loupe"
    }],
    [
        {
            title: 'RESPONSIVE DESIGN',
            description: "Optimisations spécifiques pour ordinateur, tablette et téléphone.",
            icon: ResponsiveIconSVG,
            altIcon: "icone responsive design"
        },
        {
            title: 'TRADUCTION EN DE NOMBREUSES LANGUES',
            description: "Pour attirer des visiteurs/clients de tous horizons",
            icon: TranslationIconSVG,
            altIcon: "icone traduction langues"
        },
        {
            title: "CREATION INTERFACE D'ADMINISTRATION",
            description: "Pour gérer vous même le contenu que vous souhaitez modifier régulièrement",
            icon: LightBulbIconSVG,
            altIcon: "icone ampoule idée"
        },
        {
            title: "MAINTENANCE DU SITE",
            description: "Afin de garantir l'accès aux utilisateurs à tout moment",
            icon: MaintenanceIconSVG,
            altIcon: "icone maintenance"
        }
    ]
]
const SectionMesServices = () => {
    const { t} = useTranslation();
    // const [{ width, height }] = useDimensions();
    // const top = ((factor*1.5-1) * width * (2 / 3) / 2).toString()+"px";
    
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const [ref, inView] = useInView({triggerOnce: true, threshold: 0.4});
    //const servicesRef = [useRef([createRef(),createRef(),createRef(),createRef()]),useRef([createRef(),createRef(),createRef(),createRef()])];
    const servicesRef = useRef([createRef(),createRef()]);

    useEffect(() => {
        if (!isMobile) {
          gsap.set(servicesRef.current[0], {autoAlpha:0, x:-200})
          gsap.set(servicesRef.current[1], {autoAlpha:0, x:200})
        }
    }, [isMobile]);

    useEffect(() => {
      if (inView && !isMobile) {
        gsap.to(servicesRef.current[0], {
            duration: 1,
            autoAlpha: 1,
            x:0,
                ease: "power2.out"
            });
            gsap.to(servicesRef.current[1], {
                duration: 1,
                autoAlpha: 1,
                x:0,
                ease: "power2.out"
            });
    }}, [servicesRef, inView, isMobile]);

    return (
        
                <Container id="sectionServices">
                    <SectionHeader
                        title={t(`sections.mes_services.titre`).toUpperCase()}
                        subtitle={t(`sections.mes_services.sous_titre`)}
                    />
                    <BigContainer ref={ref}>
                        {["left","right"].map( (position, i) => (
                            <ServicesContainer key={position} position={position} ref={el => servicesRef.current[i] = el}>
                                {services[i].map((service, index) => (
                                        <InfosWithIcon
                                            key={1000*(i+1)*(index+2)}
                                            position={position}
                                            title={t(`sections.mes_services.services_titre.${position==="left"?index:index+4}`).toUpperCase()}
                                            description={t(`sections.mes_services.services_description.${position==="left"?index:index+4}`)}
                                            icon={service.icon}
                                            perspective={isMobile ? false : true}
                                            titleColor="#28758e"
                                            descriptionColor="black"
                                            distanceIconInfos="2rem"
                                            altIcon={service.altIcon}
                                            type="service"
                                        />
                                ))}
                            </ServicesContainer>
                        ))}
                    </BigContainer>
                </Container>
        
    )
};

export default SectionMesServices;