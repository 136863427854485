import styled from 'styled-components';

import { ReactComponent as HikingSVG} from '../../assets/hiking.svg';
import { ReactComponent as HumanProfileSVG} from '../../assets/human-profile.svg';

export const Container= styled.div`
    position: relative;
    width: 100vw;
    height: 120vh;
    font-size: 1.2rem;
    ${(props) => props.theme.mobile`
        height: 150vh;
        font-size: 1rem;
   `}
       ${(props) => props.theme.smallMobile`
        height: 210vh;
   `}
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.colors.third};
`;

export const TwoRowContainer = styled.div`
    position: relative;
    width: 80%;
    height: 75%;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    justify-content: space-evenly;
`;

export const Row = styled.div`
    position: relative;
    width: 100%;
    height: 48%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    ${(props) => props.theme.mobile`
        margin-top: 2rem;
   `}
`;

export const Description = styled.p`
    position: relative;
    flex: 1;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    text-align: left;
`;

export const C = styled.span`
    color: ${props => props.theme.colors.second};
    font-weight: bold;
`;

export const HikingSvg = styled(HikingSVG)`
  flex: 0.8;
  margin-left: 5%;
`;

export const HumanProfileSvg = styled(HumanProfileSVG)`
  flex: 0.8;
  margin-left: 5%;
`;