import React from 'react';
import { useTranslation } from 'react-i18next';

import LocationIcon from '../../assets/location.svg';
import EmailIcon from '../../assets/mail.svg';
import PersonnalInfosIcon from '../../assets/personnal-infos.svg';
import PhoneIcon from '../../assets/phone.svg';

import SocialNetworks from '../../components/social-networks/social-networks.component';
import MentionsLegales from '../../components/mentions-legales/mentions-legales.component';

import { Container, ColumnContainer, TitleAndDescription, Title, Description, ContactInfos, ContactInfo, Icon, Info, Divider} from "./footer.styles";

import { useMediaQuery } from 'react-responsive';

const Footer = () => {
    const { t} = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 767 })

    const icons = [
        EmailIcon,
        PhoneIcon,
        PersonnalInfosIcon,
        LocationIcon
    ]

    return (
        <Container isMobile={isMobile}>
                <ColumnContainer>
                    <TitleAndDescription>
                        <Title>
                            {t('sections.footer.titre')}
                        </Title>
                        <Description>
                            {t('sections.footer.description')}
                        </Description>
                    </TitleAndDescription>
                    <SocialNetworks />
                    <ContactInfos>
                        {icons.map((source, index) => (
                            <ContactInfo key={'my key is'+index.toString()}>
                                <Icon src={source} alt={"description de l'icone"+index.toString()}/>
                                <Info>
                                    {t(`sections.footer.contact_info.${index}`)}
                                </Info>
                            </ContactInfo>
                        ))}
                    </ContactInfos>
                </ColumnContainer>
                <Divider />
                <MentionsLegales />
        </Container>
    )
};

export default Footer;