import styled from 'styled-components';

import { ReactComponent as CodingSVG} from '../../assets/coding.svg';

export const Container= styled.div`
    width: 100vw;
    height: 100vh;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
`;

export const CompetenceBarContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
`;

export const MultiColumnContainer = styled.div`
    position: relative;
    width: 90%;
    height: 100%;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    ${(props) => props.theme.mobile`
        flex-direction: column;
   `}
`;

export const ColumnContainer = styled.div`
    position: relative;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Title = styled.div`
    height: 3rem;
    width: 80%;
    margin-left: 10%;
    font-size: 1.5em;
    color: black;
    text-align: left;
    ${(props) => props.theme.tablet`
        font-size:1.2rem;
   `}
   ${(props) => props.theme.mobile`
        font-size:1rem;
   `}
`;

export const CodingSvg = styled(CodingSVG)`
  flex: 0.5;
  margin-left: auto;
  margin-right: auto;
`;